export const albums_data = {
   "bathrooms": {
      "name": "bathrooms",
      "photos": [
         {
            "faces": [],
            "height": 443,
            "name": "000.jpeg",
            "slug": "000-jpeg",
            "src": "/static/_gallery/albums/bathrooms/499x443_000-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/499x443_000-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/499x443_000-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/499x443_000-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/499x443_000-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/499x443_000-jpeg.jpeg",
            "width": 499
         },
         {
            "faces": [],
            "height": 1280,
            "name": "001.jpeg",
            "slug": "001-jpeg",
            "src": "/static/_gallery/albums/bathrooms/960x1280_001-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_001-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_001-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x666_001-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x1066_001-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x666_001-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 1280,
            "name": "002.jpeg",
            "slug": "002-jpeg",
            "src": "/static/_gallery/albums/bathrooms/960x1280_002-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_002-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_002-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x666_002-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x1066_002-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x666_002-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 1280,
            "name": "003.jpeg",
            "slug": "003-jpeg",
            "src": "/static/_gallery/albums/bathrooms/960x1280_003-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_003-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_003-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x666_003-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x1066_003-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x666_003-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 1280,
            "name": "004.jpeg",
            "slug": "004-jpeg",
            "src": "/static/_gallery/albums/bathrooms/960x1280_004-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_004-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_004-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x666_004-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x1066_004-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x666_004-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 1280,
            "name": "005.jpeg",
            "slug": "005-jpeg",
            "src": "/static/_gallery/albums/bathrooms/960x1280_005-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_005-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_005-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x666_005-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x1066_005-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x666_005-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 639,
            "name": "005.jpg",
            "slug": "005-jpg",
            "src": "/static/_gallery/albums/bathrooms/720x639_005-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/720x639_005-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/720x639_005-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x443_005-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/720x639_005-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x443_005-jpg.jpg",
            "width": 720
         },
         {
            "faces": [],
            "height": 1280,
            "name": "006.jpeg",
            "slug": "006-jpeg",
            "src": "/static/_gallery/albums/bathrooms/960x1280_006-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_006-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_006-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x666_006-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x1066_006-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x666_006-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 1280,
            "name": "007.jpeg",
            "slug": "007-jpeg",
            "src": "/static/_gallery/albums/bathrooms/960x1280_007-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_007-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_007-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x666_007-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x1066_007-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x666_007-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 1280,
            "name": "008.jpeg",
            "slug": "008-jpeg",
            "src": "/static/_gallery/albums/bathrooms/960x1280_008-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_008-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_008-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x666_008-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x1066_008-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x666_008-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 1280,
            "name": "009.jpeg",
            "slug": "009-jpeg",
            "src": "/static/_gallery/albums/bathrooms/960x1280_009-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_009-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_009-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x666_009-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x1066_009-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x666_009-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 1280,
            "name": "010.jpeg",
            "slug": "010-jpeg",
            "src": "/static/_gallery/albums/bathrooms/960x1280_010-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_010-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_010-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x666_010-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x1066_010-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x666_010-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 1280,
            "name": "011.jpeg",
            "slug": "011-jpeg",
            "src": "/static/_gallery/albums/bathrooms/960x1280_011-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_011-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_011-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x666_011-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x1066_011-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x666_011-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 1280,
            "name": "012.jpeg",
            "slug": "012-jpeg",
            "src": "/static/_gallery/albums/bathrooms/960x1280_012-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_012-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_012-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x666_012-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x1066_012-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x666_012-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 2448,
            "name": "012.jpg",
            "slug": "012-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_012-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_012-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_012-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_012-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_012-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_012-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 1280,
            "name": "013.jpeg",
            "slug": "013-jpeg",
            "src": "/static/_gallery/albums/bathrooms/960x1280_013-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_013-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_013-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x666_013-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x1066_013-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x666_013-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 2448,
            "name": "013.jpg",
            "slug": "013-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_013-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_013-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_013-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_013-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_013-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_013-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 1280,
            "name": "014.jpeg",
            "slug": "014-jpeg",
            "src": "/static/_gallery/albums/bathrooms/960x1280_014-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_014-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_014-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x666_014-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x1066_014-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x666_014-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 2448,
            "name": "014.jpg",
            "slug": "014-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_014-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_014-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_014-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_014-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_014-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_014-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 960,
            "name": "015.jpeg",
            "slug": "015-jpeg",
            "src": "/static/_gallery/albums/bathrooms/1280x960_015-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_015-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1280x960_015-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_015-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_015-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_015-jpeg.jpeg",
            "width": 1280
         },
         {
            "faces": [],
            "height": 2448,
            "name": "015.jpg",
            "slug": "015-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_015-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_015-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_015-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_015-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_015-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_015-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 1280,
            "name": "016.jpeg",
            "slug": "016-jpeg",
            "src": "/static/_gallery/albums/bathrooms/960x1280_016-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_016-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_016-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x666_016-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x1066_016-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x666_016-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 2448,
            "name": "016.jpg",
            "slug": "016-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_016-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_016-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_016-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_016-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_016-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_016-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 1280,
            "name": "017.jpeg",
            "slug": "017-jpeg",
            "src": "/static/_gallery/albums/bathrooms/960x1280_017-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_017-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_017-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x666_017-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x1066_017-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x666_017-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 2448,
            "name": "017.jpg",
            "slug": "017-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_017-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_017-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_017-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_017-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_017-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_017-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 1280,
            "name": "018.jpeg",
            "slug": "018-jpeg",
            "src": "/static/_gallery/albums/bathrooms/960x1280_018-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_018-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_018-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x666_018-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x1066_018-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x666_018-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 1280,
            "name": "019.jpeg",
            "slug": "019-jpeg",
            "src": "/static/_gallery/albums/bathrooms/960x1280_019-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_019-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_019-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x666_019-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x1066_019-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x666_019-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 2448,
            "name": "019.jpg",
            "slug": "019-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_019-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_019-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_019-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_019-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_019-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_019-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 1280,
            "name": "020.jpeg",
            "slug": "020-jpeg",
            "src": "/static/_gallery/albums/bathrooms/960x1280_020-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_020-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/960x1280_020-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x666_020-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x1066_020-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x666_020-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 2448,
            "name": "020.jpg",
            "slug": "020-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_020-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_020-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_020-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_020-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_020-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_020-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "021.jpg",
            "slug": "021-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_021-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_021-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_021-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_021-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_021-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_021-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "022.jpg",
            "slug": "022-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_022-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_022-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_022-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_022-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_022-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_022-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "023.jpg",
            "slug": "023-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_023-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_023-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_023-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_023-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_023-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_023-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "024.jpg",
            "slug": "024-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_024-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_024-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_024-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_024-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_024-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_024-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "025.jpg",
            "slug": "025-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_025-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_025-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_025-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_025-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_025-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_025-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "026.jpg",
            "slug": "026-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_026-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_026-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_026-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_026-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_026-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_026-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "027.jpg",
            "slug": "027-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_027-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_027-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_027-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_027-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_027-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_027-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "028.jpg",
            "slug": "028-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_028-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_028-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_028-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_028-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_028-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_028-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "029.jpg",
            "slug": "029-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_029-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_029-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_029-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_029-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_029-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_029-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 575,
            "name": "030.jpg",
            "slug": "030-jpg",
            "src": "/static/_gallery/albums/bathrooms/640x575_030-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/640x575_030-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/640x575_030-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x449_030-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/640x575_030-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x449_030-jpg.jpg",
            "width": 640
         },
         {
            "faces": [],
            "height": 500,
            "name": "031.jpg",
            "slug": "031-jpg",
            "src": "/static/_gallery/albums/bathrooms/400x500_031-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/400x500_031-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/400x500_031-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/400x500_031-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/400x500_031-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/400x500_031-jpg.jpg",
            "width": 400
         },
         {
            "faces": [],
            "height": 734,
            "name": "032.jpg",
            "slug": "032-jpg",
            "src": "/static/_gallery/albums/bathrooms/550x734_032-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/550x734_032-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/550x734_032-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/499x667_032-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/550x734_032-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/499x667_032-jpg.jpg",
            "width": 550
         },
         {
            "faces": [],
            "height": 908,
            "name": "033.png",
            "slug": "033-png",
            "src": "/static/_gallery/albums/bathrooms/640x908_033-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/640x908_033-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/640x908_033-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x709_033-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/640x908_033-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x709_033-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 908,
            "name": "034.png",
            "slug": "034-png",
            "src": "/static/_gallery/albums/bathrooms/640x908_034-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/640x908_034-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/640x908_034-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x709_034-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/640x908_034-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x709_034-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 951,
            "name": "035.png",
            "slug": "035-png",
            "src": "/static/_gallery/albums/bathrooms/640x951_035-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/640x951_035-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/640x951_035-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x742_035-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/640x951_035-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x742_035-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 943,
            "name": "036.png",
            "slug": "036-png",
            "src": "/static/_gallery/albums/bathrooms/640x943_036-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/640x943_036-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/640x943_036-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x736_036-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/640x943_036-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x736_036-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 912,
            "name": "037.png",
            "slug": "037-png",
            "src": "/static/_gallery/albums/bathrooms/640x912_037-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/640x912_037-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/640x912_037-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x712_037-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/640x912_037-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x712_037-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 941,
            "name": "038.png",
            "slug": "038-png",
            "src": "/static/_gallery/albums/bathrooms/640x941_038-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/640x941_038-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/640x941_038-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x735_038-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/640x941_038-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x735_038-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 905,
            "name": "039.png",
            "slug": "039-png",
            "src": "/static/_gallery/albums/bathrooms/640x905_039-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/640x905_039-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/640x905_039-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x707_039-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/640x905_039-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x707_039-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 2448,
            "name": "102.jpg",
            "slug": "102-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_102-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_102-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_102-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_102-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_102-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_102-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "103.jpg",
            "slug": "103-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_103-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_103-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_103-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_103-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_103-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_103-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "104.jpg",
            "slug": "104-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_104-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_104-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_104-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_104-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_104-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_104-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "106.jpg",
            "slug": "106-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_106-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_106-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_106-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_106-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_106-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_106-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "107.jpg",
            "slug": "107-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_107-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_107-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_107-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_107-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_107-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_107-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "108.jpg",
            "slug": "108-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_108-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_108-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_108-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_108-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_108-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_108-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "109.jpg",
            "slug": "109-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_109-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_109-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_109-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_109-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_109-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_109-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "110.jpg",
            "slug": "110-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_110-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_110-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_110-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_110-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_110-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_110-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "111.jpg",
            "slug": "111-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_111-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_111-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_111-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_111-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_111-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_111-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "118.jpg",
            "slug": "118-jpg",
            "src": "/static/_gallery/albums/bathrooms/1600x1200_118-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bathrooms/1024x768_118-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bathrooms/1600x1200_118-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bathrooms/500x375_118-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bathrooms/800x600_118-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bathrooms/500x375_118-jpg.jpg",
            "width": 3264
         }
      ],
      "slug": "bathrooms",
      "src": "/static/_gallery/albums/bathrooms/499x443_000-jpeg.jpeg"
   },
   "construction-and-buildings": {
      "name": "construction and buildings",
      "photos": [
         {
            "faces": [],
            "height": 703,
            "name": "001.jpeg",
            "slug": "001-jpeg",
            "src": "/static/_gallery/albums/construction-and-buildings/500x703_001-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/construction-and-buildings/500x703_001-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/construction-and-buildings/500x703_001-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/construction-and-buildings/500x703_001-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/construction-and-buildings/500x703_001-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/construction-and-buildings/500x703_001-jpeg.jpeg",
            "width": 500
         },
         {
            "faces": [],
            "height": 2448,
            "name": "003.jpg",
            "slug": "003-jpg",
            "src": "/static/_gallery/albums/construction-and-buildings/1600x1200_003-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/construction-and-buildings/1024x768_003-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/construction-and-buildings/1600x1200_003-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/construction-and-buildings/500x375_003-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/construction-and-buildings/800x600_003-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/construction-and-buildings/500x375_003-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "004.jpg",
            "slug": "004-jpg",
            "src": "/static/_gallery/albums/construction-and-buildings/1600x1200_004-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/construction-and-buildings/1024x768_004-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/construction-and-buildings/1600x1200_004-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/construction-and-buildings/500x375_004-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/construction-and-buildings/800x600_004-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/construction-and-buildings/500x375_004-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "007.jpg",
            "slug": "007-jpg",
            "src": "/static/_gallery/albums/construction-and-buildings/1600x1200_007-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/construction-and-buildings/1024x768_007-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/construction-and-buildings/1600x1200_007-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/construction-and-buildings/500x375_007-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/construction-and-buildings/800x600_007-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/construction-and-buildings/500x375_007-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "009.jpg",
            "slug": "009-jpg",
            "src": "/static/_gallery/albums/construction-and-buildings/1600x1200_009-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/construction-and-buildings/1024x768_009-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/construction-and-buildings/1600x1200_009-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/construction-and-buildings/500x375_009-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/construction-and-buildings/800x600_009-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/construction-and-buildings/500x375_009-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "010.jpg",
            "slug": "010-jpg",
            "src": "/static/_gallery/albums/construction-and-buildings/1600x1200_010-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/construction-and-buildings/1024x768_010-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/construction-and-buildings/1600x1200_010-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/construction-and-buildings/500x375_010-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/construction-and-buildings/800x600_010-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/construction-and-buildings/500x375_010-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 617,
            "name": "012.png",
            "slug": "012-png",
            "src": "/static/_gallery/albums/construction-and-buildings/640x617_012-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/construction-and-buildings/640x617_012-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/construction-and-buildings/640x617_012-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/construction-and-buildings/500x482_012-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/construction-and-buildings/640x617_012-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/construction-and-buildings/500x482_012-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 900,
            "name": "015.png",
            "slug": "015-png",
            "src": "/static/_gallery/albums/construction-and-buildings/640x900_015-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/construction-and-buildings/640x900_015-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/construction-and-buildings/640x900_015-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/construction-and-buildings/500x703_015-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/construction-and-buildings/640x900_015-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/construction-and-buildings/500x703_015-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 906,
            "name": "016.png",
            "slug": "016-png",
            "src": "/static/_gallery/albums/construction-and-buildings/640x906_016-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/construction-and-buildings/640x906_016-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/construction-and-buildings/640x906_016-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/construction-and-buildings/500x707_016-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/construction-and-buildings/640x906_016-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/construction-and-buildings/500x707_016-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 910,
            "name": "017.png",
            "slug": "017-png",
            "src": "/static/_gallery/albums/construction-and-buildings/640x910_017-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/construction-and-buildings/640x910_017-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/construction-and-buildings/640x910_017-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/construction-and-buildings/500x710_017-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/construction-and-buildings/640x910_017-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/construction-and-buildings/500x710_017-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 940,
            "name": "018.png",
            "slug": "018-png",
            "src": "/static/_gallery/albums/construction-and-buildings/640x940_018-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/construction-and-buildings/640x940_018-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/construction-and-buildings/640x940_018-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/construction-and-buildings/500x734_018-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/construction-and-buildings/640x940_018-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/construction-and-buildings/500x734_018-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 2448,
            "name": "019.jpg",
            "slug": "019-jpg",
            "src": "/static/_gallery/albums/construction-and-buildings/1600x1200_019-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/construction-and-buildings/1024x768_019-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/construction-and-buildings/1600x1200_019-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/construction-and-buildings/500x375_019-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/construction-and-buildings/800x600_019-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/construction-and-buildings/500x375_019-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "020.jpg",
            "slug": "020-jpg",
            "src": "/static/_gallery/albums/construction-and-buildings/1600x1200_020-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/construction-and-buildings/1024x768_020-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/construction-and-buildings/1600x1200_020-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/construction-and-buildings/500x375_020-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/construction-and-buildings/800x600_020-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/construction-and-buildings/500x375_020-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 1936,
            "name": "021.jpg",
            "slug": "021-jpg",
            "src": "/static/_gallery/albums/construction-and-buildings/1600x1195_021-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/construction-and-buildings/1024x764_021-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/construction-and-buildings/1600x1195_021-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/construction-and-buildings/500x373_021-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/construction-and-buildings/800x597_021-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/construction-and-buildings/500x373_021-jpg.jpg",
            "width": 2592
         },
         {
            "faces": [],
            "height": 2448,
            "name": "022.jpg",
            "slug": "022-jpg",
            "src": "/static/_gallery/albums/construction-and-buildings/1600x1200_022-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/construction-and-buildings/1024x768_022-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/construction-and-buildings/1600x1200_022-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/construction-and-buildings/500x375_022-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/construction-and-buildings/800x600_022-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/construction-and-buildings/500x375_022-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "102.jpg",
            "slug": "102-jpg",
            "src": "/static/_gallery/albums/construction-and-buildings/1600x1200_102-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/construction-and-buildings/1024x768_102-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/construction-and-buildings/1600x1200_102-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/construction-and-buildings/500x375_102-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/construction-and-buildings/800x600_102-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/construction-and-buildings/500x375_102-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "105.jpg",
            "slug": "105-jpg",
            "src": "/static/_gallery/albums/construction-and-buildings/1600x1200_105-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/construction-and-buildings/1024x768_105-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/construction-and-buildings/1600x1200_105-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/construction-and-buildings/500x375_105-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/construction-and-buildings/800x600_105-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/construction-and-buildings/500x375_105-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "106.jpg",
            "slug": "106-jpg",
            "src": "/static/_gallery/albums/construction-and-buildings/1600x1200_106-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/construction-and-buildings/1024x768_106-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/construction-and-buildings/1600x1200_106-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/construction-and-buildings/500x375_106-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/construction-and-buildings/800x600_106-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/construction-and-buildings/500x375_106-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "111.jpg",
            "slug": "111-jpg",
            "src": "/static/_gallery/albums/construction-and-buildings/1600x1200_111-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/construction-and-buildings/1024x768_111-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/construction-and-buildings/1600x1200_111-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/construction-and-buildings/500x375_111-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/construction-and-buildings/800x600_111-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/construction-and-buildings/500x375_111-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "113.jpg",
            "slug": "113-jpg",
            "src": "/static/_gallery/albums/construction-and-buildings/1600x1200_113-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/construction-and-buildings/1024x768_113-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/construction-and-buildings/1600x1200_113-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/construction-and-buildings/500x375_113-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/construction-and-buildings/800x600_113-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/construction-and-buildings/500x375_113-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "114.jpg",
            "slug": "114-jpg",
            "src": "/static/_gallery/albums/construction-and-buildings/1600x1200_114-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/construction-and-buildings/1024x768_114-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/construction-and-buildings/1600x1200_114-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/construction-and-buildings/500x375_114-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/construction-and-buildings/800x600_114-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/construction-and-buildings/500x375_114-jpg.jpg",
            "width": 3264
         }
      ],
      "slug": "construction-and-buildings",
      "src": "/static/_gallery/albums/construction-and-buildings/500x703_001-jpeg.jpeg"
   },
   "furniture": {
      "name": "furniture",
      "photos": [
         {
            "faces": [],
            "height": 333,
            "name": "001.jpeg",
            "slug": "001-jpeg",
            "src": "/static/_gallery/albums/furniture/499x333_001-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/499x333_001-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/499x333_001-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/499x333_001-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/499x333_001-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/499x333_001-jpeg.jpeg",
            "width": 499
         },
         {
            "faces": [],
            "height": 768,
            "name": "002.jpeg",
            "slug": "002-jpeg",
            "src": "/static/_gallery/albums/furniture/1024x768_002-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_002-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1024x768_002-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_002-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_002-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_002-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 909,
            "name": "002.png",
            "slug": "002-png",
            "src": "/static/_gallery/albums/furniture/640x909_002-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/640x909_002-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/640x909_002-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x710_002-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/640x909_002-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x710_002-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 768,
            "name": "003.jpeg",
            "slug": "003-jpeg",
            "src": "/static/_gallery/albums/furniture/1024x768_003-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_003-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1024x768_003-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_003-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_003-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_003-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 558,
            "name": "003.png",
            "slug": "003-png",
            "src": "/static/_gallery/albums/furniture/640x558_003-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/640x558_003-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/640x558_003-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x435_003-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/640x558_003-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x435_003-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 1024,
            "name": "004.jpeg",
            "slug": "004-jpeg",
            "src": "/static/_gallery/albums/furniture/766x1024_004-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/766x1024_004-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/766x1024_004-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x668_004-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/766x1024_004-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x668_004-jpeg.jpeg",
            "width": 766
         },
         {
            "faces": [],
            "height": 556,
            "name": "004.png",
            "slug": "004-png",
            "src": "/static/_gallery/albums/furniture/640x556_004-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/640x556_004-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/640x556_004-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x434_004-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/640x556_004-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x434_004-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 1024,
            "name": "005.jpeg",
            "slug": "005-jpeg",
            "src": "/static/_gallery/albums/furniture/768x1024_005-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/768x1024_005-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/768x1024_005-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x666_005-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/768x1024_005-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x666_005-jpeg.jpeg",
            "width": 768
         },
         {
            "faces": [],
            "height": 556,
            "name": "005.png",
            "slug": "005-png",
            "src": "/static/_gallery/albums/furniture/640x556_005-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/640x556_005-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/640x556_005-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x434_005-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/640x556_005-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x434_005-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 1024,
            "name": "006.jpeg",
            "slug": "006-jpeg",
            "src": "/static/_gallery/albums/furniture/768x1024_006-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/768x1024_006-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/768x1024_006-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x666_006-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/768x1024_006-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x666_006-jpeg.jpeg",
            "width": 768
         },
         {
            "faces": [],
            "height": 904,
            "name": "006.png",
            "slug": "006-png",
            "src": "/static/_gallery/albums/furniture/640x904_006-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/640x904_006-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/640x904_006-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x706_006-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/640x904_006-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x706_006-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 768,
            "name": "007.jpeg",
            "slug": "007-jpeg",
            "src": "/static/_gallery/albums/furniture/1024x768_007-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_007-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1024x768_007-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_007-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_007-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_007-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 540,
            "name": "007.png",
            "slug": "007-png",
            "src": "/static/_gallery/albums/furniture/640x540_007-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/640x540_007-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/640x540_007-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x421_007-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/640x540_007-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x421_007-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 1024,
            "name": "008.jpeg",
            "slug": "008-jpeg",
            "src": "/static/_gallery/albums/furniture/766x1024_008-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/766x1024_008-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/766x1024_008-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x668_008-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/766x1024_008-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x668_008-jpeg.jpeg",
            "width": 766
         },
         {
            "faces": [],
            "height": 1280,
            "name": "009.jpeg",
            "slug": "009-jpeg",
            "src": "/static/_gallery/albums/furniture/960x1280_009-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/960x1280_009-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/960x1280_009-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x666_009-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x1066_009-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x666_009-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 2448,
            "name": "009.jpg",
            "slug": "009-jpg",
            "src": "/static/_gallery/albums/furniture/1600x1200_009-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_009-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1600x1200_009-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_009-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_009-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_009-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "010.jpg",
            "slug": "010-jpg",
            "src": "/static/_gallery/albums/furniture/1600x1200_010-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_010-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1600x1200_010-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_010-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_010-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_010-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 960,
            "name": "011.jpeg",
            "slug": "011-jpeg",
            "src": "/static/_gallery/albums/furniture/1280x960_011-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_011-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1280x960_011-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_011-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_011-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_011-jpeg.jpeg",
            "width": 1280
         },
         {
            "faces": [],
            "height": 2448,
            "name": "011.jpg",
            "slug": "011-jpg",
            "src": "/static/_gallery/albums/furniture/1600x1200_011-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_011-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1600x1200_011-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_011-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_011-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_011-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 405,
            "name": "012.jpg",
            "slug": "012-jpg",
            "src": "/static/_gallery/albums/furniture/720x405_012-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/720x405_012-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/720x405_012-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x281_012-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/720x405_012-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x281_012-jpg.jpg",
            "width": 720
         },
         {
            "faces": [],
            "height": 405,
            "name": "014.jpg",
            "slug": "014-jpg",
            "src": "/static/_gallery/albums/furniture/720x405_014-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/720x405_014-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/720x405_014-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x281_014-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/720x405_014-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x281_014-jpg.jpg",
            "width": 720
         },
         {
            "faces": [],
            "height": 480,
            "name": "016.jpg",
            "slug": "016-jpg",
            "src": "/static/_gallery/albums/furniture/720x480_016-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/720x480_016-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/720x480_016-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x333_016-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/720x480_016-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x333_016-jpg.jpg",
            "width": 720
         },
         {
            "faces": [],
            "height": 405,
            "name": "017.jpg",
            "slug": "017-jpg",
            "src": "/static/_gallery/albums/furniture/720x405_017-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/720x405_017-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/720x405_017-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x281_017-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/720x405_017-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x281_017-jpg.jpg",
            "width": 720
         },
         {
            "faces": [],
            "height": 405,
            "name": "018.jpg",
            "slug": "018-jpg",
            "src": "/static/_gallery/albums/furniture/720x405_018-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/720x405_018-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/720x405_018-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x281_018-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/720x405_018-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x281_018-jpg.jpg",
            "width": 720
         },
         {
            "faces": [],
            "height": 479,
            "name": "019.jpg",
            "slug": "019-jpg",
            "src": "/static/_gallery/albums/furniture/720x479_019-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/720x479_019-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/720x479_019-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x332_019-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/720x479_019-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x332_019-jpg.jpg",
            "width": 720
         },
         {
            "faces": [],
            "height": 481,
            "name": "020.jpg",
            "slug": "020-jpg",
            "src": "/static/_gallery/albums/furniture/640x481_020-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/640x481_020-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/640x481_020-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_020-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/640x481_020-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_020-jpg.jpg",
            "width": 640
         },
         {
            "faces": [],
            "height": 520,
            "name": "021.jpg",
            "slug": "021-jpg",
            "src": "/static/_gallery/albums/furniture/640x520_021-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/640x520_021-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/640x520_021-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x406_021-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/640x520_021-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x406_021-jpg.jpg",
            "width": 640
         },
         {
            "faces": [],
            "height": 1136,
            "name": "022.jpg",
            "slug": "022-jpg",
            "src": "/static/_gallery/albums/furniture/640x1136_022-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/640x1136_022-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/640x1136_022-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x887_022-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/640x1136_022-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x887_022-jpg.jpg",
            "width": 640
         },
         {
            "faces": [],
            "height": 2448,
            "name": "024.jpg",
            "slug": "024-jpg",
            "src": "/static/_gallery/albums/furniture/1600x1200_024-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_024-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1600x1200_024-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_024-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_024-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_024-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "026.jpg",
            "slug": "026-jpg",
            "src": "/static/_gallery/albums/furniture/1600x1200_026-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_026-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1600x1200_026-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_026-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_026-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_026-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "027.jpg",
            "slug": "027-jpg",
            "src": "/static/_gallery/albums/furniture/1600x1200_027-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_027-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1600x1200_027-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_027-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_027-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_027-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "028.jpg",
            "slug": "028-jpg",
            "src": "/static/_gallery/albums/furniture/1600x1200_028-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_028-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1600x1200_028-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_028-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_028-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_028-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 960,
            "name": "030.jpg",
            "slug": "030-jpg",
            "src": "/static/_gallery/albums/furniture/1280x960_030-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_030-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1280x960_030-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_030-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_030-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_030-jpg.jpg",
            "width": 1280
         },
         {
            "faces": [],
            "height": 2448,
            "name": "031.jpg",
            "slug": "031-jpg",
            "src": "/static/_gallery/albums/furniture/1600x1200_031-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_031-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1600x1200_031-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_031-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_031-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_031-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "032.jpg",
            "slug": "032-jpg",
            "src": "/static/_gallery/albums/furniture/1600x1200_032-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_032-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1600x1200_032-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_032-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_032-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_032-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "033.jpg",
            "slug": "033-jpg",
            "src": "/static/_gallery/albums/furniture/1600x1200_033-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_033-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1600x1200_033-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_033-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_033-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_033-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "034.jpg",
            "slug": "034-jpg",
            "src": "/static/_gallery/albums/furniture/1600x1200_034-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_034-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1600x1200_034-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_034-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_034-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_034-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "035.jpg",
            "slug": "035-jpg",
            "src": "/static/_gallery/albums/furniture/1600x1200_035-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_035-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1600x1200_035-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_035-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_035-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_035-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 960,
            "name": "036.jpg",
            "slug": "036-jpg",
            "src": "/static/_gallery/albums/furniture/960x960_036-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/960x960_036-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/960x960_036-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x500_036-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x800_036-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x500_036-jpg.jpg",
            "width": 960
         },
         {
            "faces": [],
            "height": 2448,
            "name": "037.jpg",
            "slug": "037-jpg",
            "src": "/static/_gallery/albums/furniture/1600x1200_037-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_037-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1600x1200_037-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_037-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_037-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_037-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "039.jpg",
            "slug": "039-jpg",
            "src": "/static/_gallery/albums/furniture/1600x1200_039-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_039-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1600x1200_039-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_039-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_039-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_039-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "041.jpg",
            "slug": "041-jpg",
            "src": "/static/_gallery/albums/furniture/1600x1200_041-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_041-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1600x1200_041-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_041-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_041-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_041-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "042.jpg",
            "slug": "042-jpg",
            "src": "/static/_gallery/albums/furniture/1600x1200_042-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_042-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1600x1200_042-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_042-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_042-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_042-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "043.jpg",
            "slug": "043-jpg",
            "src": "/static/_gallery/albums/furniture/1600x1200_043-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_043-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1600x1200_043-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_043-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_043-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_043-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 906,
            "name": "044.png",
            "slug": "044-png",
            "src": "/static/_gallery/albums/furniture/640x906_044-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/640x906_044-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/640x906_044-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x707_044-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/640x906_044-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x707_044-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 917,
            "name": "046.png",
            "slug": "046-png",
            "src": "/static/_gallery/albums/furniture/640x917_046-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/640x917_046-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/640x917_046-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x716_046-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/640x917_046-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x716_046-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 912,
            "name": "047.png",
            "slug": "047-png",
            "src": "/static/_gallery/albums/furniture/640x912_047-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/640x912_047-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/640x912_047-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x712_047-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/640x912_047-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x712_047-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 907,
            "name": "048.png",
            "slug": "048-png",
            "src": "/static/_gallery/albums/furniture/640x907_048-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/640x907_048-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/640x907_048-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x708_048-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/640x907_048-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x708_048-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 906,
            "name": "049.png",
            "slug": "049-png",
            "src": "/static/_gallery/albums/furniture/640x906_049-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/640x906_049-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/640x906_049-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x707_049-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/640x906_049-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x707_049-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 939,
            "name": "050.png",
            "slug": "050-png",
            "src": "/static/_gallery/albums/furniture/640x939_050-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/640x939_050-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/640x939_050-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x733_050-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/640x939_050-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x733_050-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 935,
            "name": "051.png",
            "slug": "051-png",
            "src": "/static/_gallery/albums/furniture/640x935_051-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/640x935_051-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/640x935_051-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x730_051-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/640x935_051-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x730_051-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 900,
            "name": "052.png",
            "slug": "052-png",
            "src": "/static/_gallery/albums/furniture/640x900_052-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/640x900_052-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/640x900_052-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x703_052-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/640x900_052-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x703_052-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 930,
            "name": "053.png",
            "slug": "053-png",
            "src": "/static/_gallery/albums/furniture/640x930_053-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/640x930_053-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/640x930_053-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x726_053-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/640x930_053-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x726_053-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 2448,
            "name": "055.jpg",
            "slug": "055-jpg",
            "src": "/static/_gallery/albums/furniture/1600x1200_055-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_055-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1600x1200_055-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_055-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_055-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_055-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "057.jpg",
            "slug": "057-jpg",
            "src": "/static/_gallery/albums/furniture/1600x1200_057-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_057-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1600x1200_057-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_057-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_057-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_057-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "060.jpg",
            "slug": "060-jpg",
            "src": "/static/_gallery/albums/furniture/1600x1200_060-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_060-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1600x1200_060-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_060-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_060-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_060-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 720,
            "name": "067.jpg",
            "slug": "067-jpg",
            "src": "/static/_gallery/albums/furniture/522x720_067-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/522x720_067-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/522x720_067-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x689_067-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/522x720_067-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x689_067-jpg.jpg",
            "width": 522
         },
         {
            "faces": [],
            "height": 540,
            "name": "069.jpg",
            "slug": "069-jpg",
            "src": "/static/_gallery/albums/furniture/720x540_069-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/720x540_069-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/720x540_069-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_069-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/720x540_069-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_069-jpg.jpg",
            "width": 720
         },
         {
            "faces": [],
            "height": 2448,
            "name": "076.jpg",
            "slug": "076-jpg",
            "src": "/static/_gallery/albums/furniture/1600x1200_076-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_076-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1600x1200_076-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_076-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_076-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_076-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "077.jpg",
            "slug": "077-jpg",
            "src": "/static/_gallery/albums/furniture/1600x1200_077-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_077-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1600x1200_077-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_077-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_077-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_077-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 240,
            "name": "079.jpg",
            "slug": "079-jpg",
            "src": "/static/_gallery/albums/furniture/358x240_079-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/358x240_079-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/358x240_079-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/358x240_079-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/358x240_079-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/358x240_079-jpg.jpg",
            "width": 358
         },
         {
            "faces": [],
            "height": 477,
            "name": "080.jpg",
            "slug": "080-jpg",
            "src": "/static/_gallery/albums/furniture/720x477_080-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/720x477_080-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/720x477_080-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x331_080-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/720x477_080-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x331_080-jpg.jpg",
            "width": 720
         },
         {
            "faces": [],
            "height": 450,
            "name": "082.jpg",
            "slug": "082-jpg",
            "src": "/static/_gallery/albums/furniture/600x450_082-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/600x450_082-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/600x450_082-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_082-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/600x450_082-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_082-jpg.jpg",
            "width": 600
         },
         {
            "faces": [],
            "height": 333,
            "name": "084.jpg",
            "slug": "084-jpg",
            "src": "/static/_gallery/albums/furniture/720x333_084-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/720x333_084-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/720x333_084-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x231_084-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/720x333_084-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x231_084-jpg.jpg",
            "width": 720
         },
         {
            "faces": [],
            "height": 2448,
            "name": "123.jpg",
            "slug": "123-jpg",
            "src": "/static/_gallery/albums/furniture/1600x1200_123-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_123-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1600x1200_123-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_123-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_123-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_123-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "125.jpg",
            "slug": "125-jpg",
            "src": "/static/_gallery/albums/furniture/1600x1200_125-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/furniture/1024x768_125-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/furniture/1600x1200_125-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/furniture/500x375_125-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/furniture/800x600_125-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/furniture/500x375_125-jpg.jpg",
            "width": 3264
         }
      ],
      "slug": "furniture",
      "src": "/static/_gallery/albums/furniture/499x333_001-jpeg.jpeg"
   },
   "granite-and-marble-entrances": {
      "name": "granite and marble entrances",
      "photos": [
         {
            "faces": [],
            "height": 353,
            "name": "001.jpeg",
            "slug": "001-jpeg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/499x353_001-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/499x353_001-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/499x353_001-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/499x353_001-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/499x353_001-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/499x353_001-jpeg.jpeg",
            "width": 499
         },
         {
            "faces": [],
            "height": 1280,
            "name": "002.jpg",
            "slug": "002-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/960x1280_002-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/960x1280_002-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/960x1280_002-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x666_002-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x1066_002-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x666_002-jpg.jpg",
            "width": 960
         },
         {
            "faces": [],
            "height": 1280,
            "name": "003.jpg",
            "slug": "003-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/960x1280_003-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/960x1280_003-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/960x1280_003-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x666_003-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x1066_003-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x666_003-jpg.jpg",
            "width": 960
         },
         {
            "faces": [],
            "height": 2448,
            "name": "004.jpg",
            "slug": "004-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_004-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_004-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_004-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_004-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_004-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_004-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "005.jpg",
            "slug": "005-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_005-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_005-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_005-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_005-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_005-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_005-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "006.jpg",
            "slug": "006-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_006-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_006-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_006-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_006-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_006-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_006-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "007.jpg",
            "slug": "007-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_007-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_007-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_007-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_007-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_007-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_007-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "008.jpg",
            "slug": "008-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_008-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_008-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_008-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_008-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_008-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_008-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "009.jpg",
            "slug": "009-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_009-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_009-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_009-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_009-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_009-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_009-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "010.jpg",
            "slug": "010-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_010-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_010-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_010-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_010-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_010-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_010-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "011.jpg",
            "slug": "011-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_011-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_011-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_011-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_011-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_011-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_011-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "012.jpg",
            "slug": "012-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_012-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_012-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_012-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_012-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_012-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_012-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "013.jpg",
            "slug": "013-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_013-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_013-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_013-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_013-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_013-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_013-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "014.jpg",
            "slug": "014-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_014-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_014-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_014-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_014-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_014-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_014-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "015.jpg",
            "slug": "015-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_015-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_015-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_015-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_015-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_015-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_015-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "016.jpg",
            "slug": "016-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_016-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_016-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_016-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_016-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_016-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_016-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "017.jpg",
            "slug": "017-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_017-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_017-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_017-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_017-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_017-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_017-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "018.jpg",
            "slug": "018-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_018-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_018-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_018-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_018-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_018-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_018-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "019.jpg",
            "slug": "019-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_019-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_019-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_019-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_019-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_019-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_019-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "020.jpg",
            "slug": "020-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_020-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_020-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_020-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_020-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_020-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_020-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "021.jpg",
            "slug": "021-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_021-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_021-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_021-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_021-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_021-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_021-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "022.jpg",
            "slug": "022-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_022-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_022-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_022-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_022-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_022-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_022-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "023.jpg",
            "slug": "023-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_023-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_023-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_023-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_023-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_023-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_023-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 912,
            "name": "024.png",
            "slug": "024-png",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/640x912_024-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/640x912_024-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/640x912_024-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x712_024-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/640x912_024-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x712_024-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 502,
            "name": "025.jpg",
            "slug": "025-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1000x502_025-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1000x502_025-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1000x502_025-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x251_025-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x401_025-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x251_025-jpg.jpg",
            "width": 1000
         },
         {
            "faces": [],
            "height": 180,
            "name": "026.jpg",
            "slug": "026-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/240x180_026-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/240x180_026-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/240x180_026-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/240x180_026-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/240x180_026-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/240x180_026-jpg.jpg",
            "width": 240
         },
         {
            "faces": [],
            "height": 500,
            "name": "027.jpg",
            "slug": "027-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/372x500_027-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/372x500_027-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/372x500_027-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/372x500_027-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/372x500_027-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/372x500_027-jpg.jpg",
            "width": 372
         },
         {
            "faces": [],
            "height": 1280,
            "name": "028.jpg",
            "slug": "028-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1600x1199_028-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x767_028-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1600x1199_028-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x374_028-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x599_028-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x374_028-jpg.jpg",
            "width": 1707
         },
         {
            "faces": [],
            "height": 2448,
            "name": "029.jpg",
            "slug": "029-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_029-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_029-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1600x1200_029-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_029-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_029-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_029-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 1344,
            "name": "030.jpg",
            "slug": "030-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_030-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_030-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_030-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_030-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_030-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_030-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1344,
            "name": "031.jpg",
            "slug": "031-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_031-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_031-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_031-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_031-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_031-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_031-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1344,
            "name": "032.jpg",
            "slug": "032-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_032-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_032-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_032-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_032-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_032-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_032-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1344,
            "name": "033.jpg",
            "slug": "033-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_033-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_033-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_033-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_033-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_033-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_033-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1280,
            "name": "034.jpg",
            "slug": "034-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/727x1280_034-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/727x1280_034-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/727x1280_034-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x880_034-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/727x1280_034-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x880_034-jpg.jpg",
            "width": 727
         },
         {
            "faces": [],
            "height": 1344,
            "name": "035.jpg",
            "slug": "035-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_035-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_035-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_035-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_035-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_035-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_035-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1344,
            "name": "036.jpg",
            "slug": "036-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_036-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_036-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_036-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_036-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_036-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_036-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1280,
            "name": "037.jpg",
            "slug": "037-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/768x1280_037-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/768x1280_037-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/768x1280_037-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x833_037-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/768x1280_037-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x833_037-jpg.jpg",
            "width": 768
         },
         {
            "faces": [],
            "height": 1280,
            "name": "038.jpg",
            "slug": "038-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/768x1280_038-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/768x1280_038-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/768x1280_038-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x833_038-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/768x1280_038-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x833_038-jpg.jpg",
            "width": 768
         },
         {
            "faces": [],
            "height": 1344,
            "name": "039.jpg",
            "slug": "039-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_039-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_039-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_039-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_039-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_039-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_039-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1344,
            "name": "040.jpg",
            "slug": "040-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_040-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_040-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_040-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_040-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_040-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_040-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1344,
            "name": "041.jpg",
            "slug": "041-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_041-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_041-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_041-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_041-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_041-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_041-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1344,
            "name": "042.jpg",
            "slug": "042-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_042-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_042-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_042-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_042-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_042-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_042-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1344,
            "name": "043.jpg",
            "slug": "043-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_043-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_043-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_043-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_043-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_043-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_043-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1344,
            "name": "044.jpg",
            "slug": "044-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_044-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_044-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_044-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_044-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_044-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_044-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1344,
            "name": "045.jpg",
            "slug": "045-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_045-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_045-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_045-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_045-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_045-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_045-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1344,
            "name": "046.jpg",
            "slug": "046-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_046-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_046-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_046-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_046-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_046-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_046-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1344,
            "name": "047.jpg",
            "slug": "047-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_047-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_047-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_047-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_047-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_047-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_047-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 960,
            "name": "048.jpg",
            "slug": "048-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/540x960_048-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/540x960_048-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/540x960_048-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/499x888_048-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/540x960_048-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/499x888_048-jpg.jpg",
            "width": 540
         },
         {
            "faces": [],
            "height": 1599,
            "name": "049.jpg",
            "slug": "049-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/899x1599_049-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/899x1599_049-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/899x1599_049-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x889_049-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x1422_049-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x889_049-jpg.jpg",
            "width": 899
         },
         {
            "faces": [],
            "height": 1599,
            "name": "050.jpg",
            "slug": "050-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/899x1599_050-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/899x1599_050-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/899x1599_050-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x889_050-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x1422_050-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x889_050-jpg.jpg",
            "width": 899
         },
         {
            "faces": [],
            "height": 1599,
            "name": "051.jpg",
            "slug": "051-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/899x1599_051-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/899x1599_051-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/899x1599_051-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x889_051-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x1422_051-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x889_051-jpg.jpg",
            "width": 899
         },
         {
            "faces": [],
            "height": 1600,
            "name": "052.jpg",
            "slug": "052-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1200x1600_052-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x1365_052-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1200x1600_052-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x666_052-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x1066_052-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x666_052-jpg.jpg",
            "width": 1200
         },
         {
            "faces": [],
            "height": 960,
            "name": "053.jpg",
            "slug": "053-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1280x960_053-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_053-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1280x960_053-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_053-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_053-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_053-jpg.jpg",
            "width": 1280
         },
         {
            "faces": [],
            "height": 1600,
            "name": "054.jpg",
            "slug": "054-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/900x1600_054-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/900x1600_054-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/900x1600_054-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x888_054-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x1422_054-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x888_054-jpg.jpg",
            "width": 900
         },
         {
            "faces": [],
            "height": 1600,
            "name": "055.jpg",
            "slug": "055-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1200x1600_055-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x1365_055-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1200x1600_055-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x666_055-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x1066_055-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x666_055-jpg.jpg",
            "width": 1200
         },
         {
            "faces": [],
            "height": 960,
            "name": "056.jpg",
            "slug": "056-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1280x960_056-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_056-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1280x960_056-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_056-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_056-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_056-jpg.jpg",
            "width": 1280
         },
         {
            "faces": [],
            "height": 332,
            "name": "057.jpg",
            "slug": "057-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/443x332_057-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/443x332_057-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/443x332_057-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/443x332_057-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/443x332_057-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/443x332_057-jpg.jpg",
            "width": 443
         },
         {
            "faces": [],
            "height": 1280,
            "name": "058.jpg",
            "slug": "058-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/960x1280_058-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/960x1280_058-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/960x1280_058-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x666_058-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x1066_058-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x666_058-jpg.jpg",
            "width": 960
         },
         {
            "faces": [],
            "height": 480,
            "name": "059.jpg",
            "slug": "059-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/640x480_059-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/640x480_059-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/640x480_059-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_059-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/640x480_059-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_059-jpg.jpg",
            "width": 640
         },
         {
            "faces": [],
            "height": 480,
            "name": "060.jpg",
            "slug": "060-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/640x480_060-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/640x480_060-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/640x480_060-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_060-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/640x480_060-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_060-jpg.jpg",
            "width": 640
         },
         {
            "faces": [],
            "height": 1280,
            "name": "061.jpg",
            "slug": "061-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/768x1280_061-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/768x1280_061-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/768x1280_061-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x833_061-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/768x1280_061-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x833_061-jpg.jpg",
            "width": 768
         },
         {
            "faces": [],
            "height": 1280,
            "name": "062.jpg",
            "slug": "062-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/960x1280_062-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/960x1280_062-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/960x1280_062-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x666_062-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x1066_062-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x666_062-jpg.jpg",
            "width": 960
         },
         {
            "faces": [],
            "height": 1280,
            "name": "063.jpg",
            "slug": "063-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/960x1280_063-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/960x1280_063-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/960x1280_063-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x666_063-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x1066_063-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x666_063-jpg.jpg",
            "width": 960
         },
         {
            "faces": [],
            "height": 1344,
            "name": "064.jpg",
            "slug": "064-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_064-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_064-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_064-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_064-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_064-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_064-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1280,
            "name": "065.jpg",
            "slug": "065-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/960x1280_065-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/960x1280_065-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/960x1280_065-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x666_065-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x1066_065-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x666_065-jpg.jpg",
            "width": 960
         },
         {
            "faces": [],
            "height": 1344,
            "name": "066.jpg",
            "slug": "066-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_066-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_066-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_066-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_066-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_066-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_066-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1344,
            "name": "067.jpg",
            "slug": "067-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_067-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_067-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_067-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_067-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_067-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_067-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1344,
            "name": "068.jpg",
            "slug": "068-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_068-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_068-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_068-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_068-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_068-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_068-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1344,
            "name": "069.jpg",
            "slug": "069-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_069-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_069-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_069-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_069-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_069-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_069-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1344,
            "name": "070.jpg",
            "slug": "070-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_070-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_070-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_070-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_070-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_070-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_070-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1344,
            "name": "071.jpg",
            "slug": "071-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_071-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_071-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_071-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_071-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_071-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_071-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1344,
            "name": "072.jpg",
            "slug": "072-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_072-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_072-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_072-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_072-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_072-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_072-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1061,
            "name": "073.jpg",
            "slug": "073-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1500x1061_073-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x724_073-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1500x1061_073-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x353_073-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x565_073-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x353_073-jpg.jpg",
            "width": 1500
         },
         {
            "faces": [],
            "height": 1344,
            "name": "074.jpg",
            "slug": "074-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_074-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_074-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_074-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_074-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_074-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_074-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 960,
            "name": "075.jpg",
            "slug": "075-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1280x960_075-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_075-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1280x960_075-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_075-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_075-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_075-jpg.jpg",
            "width": 1280
         },
         {
            "faces": [],
            "height": 960,
            "name": "076.jpg",
            "slug": "076-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1280x960_076-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_076-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1280x960_076-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_076-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_076-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_076-jpg.jpg",
            "width": 1280
         },
         {
            "faces": [],
            "height": 960,
            "name": "077.jpg",
            "slug": "077-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1280x960_077-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x768_077-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1280x960_077-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x375_077-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x600_077-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x375_077-jpg.jpg",
            "width": 1280
         },
         {
            "faces": [],
            "height": 1280,
            "name": "078.jpg",
            "slug": "078-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/960x1280_078-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/960x1280_078-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/960x1280_078-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x666_078-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x1066_078-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x666_078-jpg.jpg",
            "width": 960
         },
         {
            "faces": [],
            "height": 1344,
            "name": "079.jpg",
            "slug": "079-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_079-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_079-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_079-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_079-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_079-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_079-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1280,
            "name": "080.jpg",
            "slug": "080-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/960x1280_080-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/960x1280_080-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/960x1280_080-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x666_080-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x1066_080-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x666_080-jpg.jpg",
            "width": 960
         },
         {
            "faces": [],
            "height": 1280,
            "name": "081.jpg",
            "slug": "081-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/960x1280_081-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/960x1280_081-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/960x1280_081-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x666_081-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x1066_081-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x666_081-jpg.jpg",
            "width": 960
         },
         {
            "faces": [],
            "height": 1344,
            "name": "082.jpg",
            "slug": "082-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_082-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_082-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_082-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_082-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_082-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_082-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1344,
            "name": "083.jpg",
            "slug": "083-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_083-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_083-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_083-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_083-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_083-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_083-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1344,
            "name": "084.jpg",
            "slug": "084-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_084-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_084-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_084-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_084-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_084-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_084-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 1344,
            "name": "085.jpg",
            "slug": "085-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_085-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_085-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_085-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_085-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_085-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_085-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 197,
            "name": "086.jpg",
            "slug": "086-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/256x197_086-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/256x197_086-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/256x197_086-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/256x197_086-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/256x197_086-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/256x197_086-jpg.jpg",
            "width": 256
         },
         {
            "faces": [],
            "height": 139,
            "name": "087.jpg",
            "slug": "087-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/186x139_087-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/186x139_087-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/186x139_087-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/186x139_087-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/186x139_087-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/186x139_087-jpg.jpg",
            "width": 186
         },
         {
            "faces": [],
            "height": 194,
            "name": "088.jpg",
            "slug": "088-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/259x194_088-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/259x194_088-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/259x194_088-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/259x194_088-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/259x194_088-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/259x194_088-jpg.jpg",
            "width": 259
         },
         {
            "faces": [],
            "height": 194,
            "name": "089.jpg",
            "slug": "089-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/259x194_089-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/259x194_089-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/259x194_089-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/259x194_089-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/259x194_089-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/259x194_089-jpg.jpg",
            "width": 259
         },
         {
            "faces": [],
            "height": 194,
            "name": "090.jpg",
            "slug": "090-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/259x194_090-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/259x194_090-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/259x194_090-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/259x194_090-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/259x194_090-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/259x194_090-jpg.jpg",
            "width": 259
         },
         {
            "faces": [],
            "height": 1344,
            "name": "091.jpg",
            "slug": "091-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/760x1344_091-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_091-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_091-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x884_091-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/760x1344_091-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x884_091-jpg.jpg",
            "width": 760
         },
         {
            "faces": [],
            "height": 363,
            "name": "092.jpg",
            "slug": "092-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/559x363_092-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/559x363_092-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/559x363_092-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/499x324_092-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/559x363_092-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/499x324_092-jpg.jpg",
            "width": 559
         },
         {
            "faces": [],
            "height": 685,
            "name": "093.jpg",
            "slug": "093-jpg",
            "src": "/static/_gallery/albums/granite-and-marble-entrances/1200x685_093-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1024x584_093-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/1200x685_093-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/500x285_093-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/granite-and-marble-entrances/800x456_093-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/granite-and-marble-entrances/500x285_093-jpg.jpg",
            "width": 1200
         }
      ],
      "slug": "granite-and-marble-entrances",
      "src": "/static/_gallery/albums/granite-and-marble-entrances/499x353_001-jpeg.jpeg"
   },
   "interior-decoration": {
      "name": "interior decoration",
      "photos": [
         {
            "faces": [],
            "height": 450,
            "name": "000.jpeg",
            "slug": "000-jpeg",
            "src": "/static/_gallery/albums/interior-decoration/500x450_000-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/500x450_000-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/500x450_000-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x450_000-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/500x450_000-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x450_000-jpeg.jpeg",
            "width": 500
         },
         {
            "faces": [],
            "height": 1280,
            "name": "001.jpeg",
            "slug": "001-jpeg",
            "src": "/static/_gallery/albums/interior-decoration/960x1280_001-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/960x1280_001-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/960x1280_001-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x666_001-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x1066_001-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x666_001-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 1280,
            "name": "002.jpeg",
            "slug": "002-jpeg",
            "src": "/static/_gallery/albums/interior-decoration/768x1280_002-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/768x1280_002-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/768x1280_002-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x833_002-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/768x1280_002-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x833_002-jpeg.jpeg",
            "width": 768
         },
         {
            "faces": [],
            "height": 576,
            "name": "003.jpeg",
            "slug": "003-jpeg",
            "src": "/static/_gallery/albums/interior-decoration/1155x576_003-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x510_003-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1155x576_003-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x249_003-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x398_003-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x249_003-jpeg.jpeg",
            "width": 1155
         },
         {
            "faces": [],
            "height": 1280,
            "name": "004.jpeg",
            "slug": "004-jpeg",
            "src": "/static/_gallery/albums/interior-decoration/960x1280_004-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/960x1280_004-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/960x1280_004-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x666_004-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x1066_004-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x666_004-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 567,
            "name": "004.png",
            "slug": "004-png",
            "src": "/static/_gallery/albums/interior-decoration/640x567_004-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/640x567_004-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/640x567_004-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x442_004-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/640x567_004-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x442_004-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 1000,
            "name": "005.jpeg",
            "slug": "005-jpeg",
            "src": "/static/_gallery/albums/interior-decoration/1280x1000_005-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x800_005-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1280x1000_005-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x390_005-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x625_005-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x390_005-jpeg.jpeg",
            "width": 1280
         },
         {
            "faces": [],
            "height": 554,
            "name": "005.png",
            "slug": "005-png",
            "src": "/static/_gallery/albums/interior-decoration/640x554_005-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/640x554_005-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/640x554_005-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x432_005-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/640x554_005-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x432_005-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 1280,
            "name": "006.jpeg",
            "slug": "006-jpeg",
            "src": "/static/_gallery/albums/interior-decoration/768x1280_006-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/768x1280_006-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/768x1280_006-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x833_006-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/768x1280_006-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x833_006-jpeg.jpeg",
            "width": 768
         },
         {
            "faces": [],
            "height": 850,
            "name": "006.png",
            "slug": "006-png",
            "src": "/static/_gallery/albums/interior-decoration/640x850_006-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/640x850_006-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/640x850_006-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x664_006-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/640x850_006-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x664_006-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 1280,
            "name": "007.jpeg",
            "slug": "007-jpeg",
            "src": "/static/_gallery/albums/interior-decoration/960x1280_007-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/960x1280_007-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/960x1280_007-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x666_007-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x1066_007-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x666_007-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 852,
            "name": "007.png",
            "slug": "007-png",
            "src": "/static/_gallery/albums/interior-decoration/640x852_007-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/640x852_007-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/640x852_007-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x665_007-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/640x852_007-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x665_007-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 1280,
            "name": "008.jpeg",
            "slug": "008-jpeg",
            "src": "/static/_gallery/albums/interior-decoration/960x1280_008-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/960x1280_008-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/960x1280_008-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x666_008-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x1066_008-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x666_008-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 1280,
            "name": "009.jpeg",
            "slug": "009-jpeg",
            "src": "/static/_gallery/albums/interior-decoration/720x1280_009-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/720x1280_009-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/720x1280_009-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x888_009-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/720x1280_009-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x888_009-jpeg.jpeg",
            "width": 720
         },
         {
            "faces": [],
            "height": 908,
            "name": "009.png",
            "slug": "009-png",
            "src": "/static/_gallery/albums/interior-decoration/640x908_009-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/640x908_009-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/640x908_009-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x709_009-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/640x908_009-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x709_009-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 1280,
            "name": "010.jpeg",
            "slug": "010-jpeg",
            "src": "/static/_gallery/albums/interior-decoration/960x1280_010-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/960x1280_010-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/960x1280_010-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x666_010-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x1066_010-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x666_010-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 559,
            "name": "010.png",
            "slug": "010-png",
            "src": "/static/_gallery/albums/interior-decoration/640x559_010-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/640x559_010-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/640x559_010-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x436_010-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/640x559_010-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x436_010-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 960,
            "name": "011.jpeg",
            "slug": "011-jpeg",
            "src": "/static/_gallery/albums/interior-decoration/1280x960_011-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_011-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1280x960_011-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_011-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_011-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_011-jpeg.jpeg",
            "width": 1280
         },
         {
            "faces": [],
            "height": 903,
            "name": "011.png",
            "slug": "011-png",
            "src": "/static/_gallery/albums/interior-decoration/640x903_011-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/640x903_011-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/640x903_011-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x705_011-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/640x903_011-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x705_011-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 1280,
            "name": "012.jpeg",
            "slug": "012-jpeg",
            "src": "/static/_gallery/albums/interior-decoration/960x1280_012-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/960x1280_012-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/960x1280_012-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x666_012-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x1066_012-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x666_012-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 609,
            "name": "012.png",
            "slug": "012-png",
            "src": "/static/_gallery/albums/interior-decoration/640x609_012-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/640x609_012-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/640x609_012-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x475_012-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/640x609_012-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x475_012-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 1280,
            "name": "013.jpeg",
            "slug": "013-jpeg",
            "src": "/static/_gallery/albums/interior-decoration/960x1280_013-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/960x1280_013-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/960x1280_013-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x666_013-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x1066_013-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x666_013-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 2448,
            "name": "013.jpg",
            "slug": "013-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_013-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_013-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_013-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_013-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_013-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_013-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 1280,
            "name": "014.jpeg",
            "slug": "014-jpeg",
            "src": "/static/_gallery/albums/interior-decoration/768x1280_014-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/768x1280_014-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/768x1280_014-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x833_014-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/768x1280_014-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x833_014-jpeg.jpeg",
            "width": 768
         },
         {
            "faces": [],
            "height": 540,
            "name": "014.jpg",
            "slug": "014-jpg",
            "src": "/static/_gallery/albums/interior-decoration/960x540_014-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/960x540_014-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/960x540_014-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x281_014-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x450_014-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x281_014-jpg.jpg",
            "width": 960
         },
         {
            "faces": [],
            "height": 1280,
            "name": "015.jpeg",
            "slug": "015-jpeg",
            "src": "/static/_gallery/albums/interior-decoration/960x1280_015-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/960x1280_015-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/960x1280_015-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x666_015-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x1066_015-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x666_015-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 800,
            "name": "015.jpg",
            "slug": "015-jpg",
            "src": "/static/_gallery/albums/interior-decoration/600x800_015-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/600x800_015-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/600x800_015-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x666_015-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/600x800_015-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x666_015-jpg.jpg",
            "width": 600
         },
         {
            "faces": [],
            "height": 1280,
            "name": "016.jpeg",
            "slug": "016-jpeg",
            "src": "/static/_gallery/albums/interior-decoration/960x1280_016-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/960x1280_016-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/960x1280_016-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x666_016-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x1066_016-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x666_016-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 800,
            "name": "016.jpg",
            "slug": "016-jpg",
            "src": "/static/_gallery/albums/interior-decoration/600x800_016-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/600x800_016-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/600x800_016-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x666_016-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/600x800_016-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x666_016-jpg.jpg",
            "width": 600
         },
         {
            "faces": [],
            "height": 800,
            "name": "017.jpg",
            "slug": "017-jpg",
            "src": "/static/_gallery/albums/interior-decoration/600x800_017-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/600x800_017-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/600x800_017-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x666_017-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/600x800_017-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x666_017-jpg.jpg",
            "width": 600
         },
         {
            "faces": [],
            "height": 2448,
            "name": "018.jpg",
            "slug": "018-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_018-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_018-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_018-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_018-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_018-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_018-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 540,
            "name": "019.jpg",
            "slug": "019-jpg",
            "src": "/static/_gallery/albums/interior-decoration/960x540_019-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/960x540_019-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/960x540_019-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x281_019-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x450_019-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x281_019-jpg.jpg",
            "width": 960
         },
         {
            "faces": [],
            "height": 540,
            "name": "020.jpg",
            "slug": "020-jpg",
            "src": "/static/_gallery/albums/interior-decoration/960x540_020-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/960x540_020-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/960x540_020-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x281_020-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x450_020-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x281_020-jpg.jpg",
            "width": 960
         },
         {
            "faces": [],
            "height": 2448,
            "name": "021.jpg",
            "slug": "021-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_021-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_021-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_021-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_021-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_021-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_021-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "022.jpg",
            "slug": "022-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_022-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_022-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_022-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_022-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_022-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_022-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "023.jpg",
            "slug": "023-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_023-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_023-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_023-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_023-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_023-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_023-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "024.jpg",
            "slug": "024-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_024-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_024-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_024-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_024-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_024-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_024-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "025.jpg",
            "slug": "025-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_025-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_025-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_025-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_025-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_025-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_025-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "026.jpg",
            "slug": "026-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_026-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_026-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_026-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_026-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_026-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_026-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "027.jpg",
            "slug": "027-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_027-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_027-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_027-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_027-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_027-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_027-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "028.jpg",
            "slug": "028-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_028-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_028-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_028-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_028-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_028-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_028-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "029.jpg",
            "slug": "029-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_029-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_029-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_029-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_029-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_029-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_029-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "030.jpg",
            "slug": "030-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_030-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_030-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_030-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_030-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_030-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_030-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "031.jpg",
            "slug": "031-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_031-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_031-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_031-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_031-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_031-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_031-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "032.jpg",
            "slug": "032-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_032-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_032-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_032-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_032-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_032-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_032-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "033.jpg",
            "slug": "033-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_033-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_033-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_033-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_033-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_033-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_033-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "034.jpg",
            "slug": "034-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_034-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_034-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_034-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_034-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_034-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_034-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "035.jpg",
            "slug": "035-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_035-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_035-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_035-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_035-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_035-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_035-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "036.jpg",
            "slug": "036-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_036-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_036-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_036-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_036-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_036-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_036-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "037.jpg",
            "slug": "037-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_037-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_037-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_037-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_037-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_037-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_037-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 1478,
            "name": "038.jpg",
            "slug": "038-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1108x1478_038-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x1365_038-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1108x1478_038-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/499x666_038-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x1067_038-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/499x666_038-jpg.jpg",
            "width": 1108
         },
         {
            "faces": [],
            "height": 2448,
            "name": "039.jpg",
            "slug": "039-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_039-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_039-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_039-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_039-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_039-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_039-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "040.jpg",
            "slug": "040-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_040-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_040-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_040-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_040-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_040-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_040-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "041.jpg",
            "slug": "041-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_041-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_041-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_041-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_041-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_041-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_041-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "042.jpg",
            "slug": "042-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_042-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_042-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_042-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_042-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_042-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_042-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "043.jpg",
            "slug": "043-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_043-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_043-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_043-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_043-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_043-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_043-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "044.jpg",
            "slug": "044-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_044-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_044-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_044-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_044-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_044-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_044-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "045.jpg",
            "slug": "045-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_045-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_045-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_045-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_045-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_045-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_045-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 906,
            "name": "046.png",
            "slug": "046-png",
            "src": "/static/_gallery/albums/interior-decoration/640x906_046-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/640x906_046-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/640x906_046-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x707_046-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/640x906_046-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x707_046-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 914,
            "name": "047.png",
            "slug": "047-png",
            "src": "/static/_gallery/albums/interior-decoration/640x914_047-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/640x914_047-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/640x914_047-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x714_047-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/640x914_047-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x714_047-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 950,
            "name": "048.png",
            "slug": "048-png",
            "src": "/static/_gallery/albums/interior-decoration/640x950_048-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/640x950_048-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/640x950_048-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x742_048-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/640x950_048-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x742_048-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 913,
            "name": "049.png",
            "slug": "049-png",
            "src": "/static/_gallery/albums/interior-decoration/640x913_049-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/640x913_049-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/640x913_049-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x713_049-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/640x913_049-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x713_049-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 905,
            "name": "050.png",
            "slug": "050-png",
            "src": "/static/_gallery/albums/interior-decoration/640x905_050-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/640x905_050-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/640x905_050-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x707_050-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/640x905_050-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x707_050-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 901,
            "name": "051.png",
            "slug": "051-png",
            "src": "/static/_gallery/albums/interior-decoration/640x901_051-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/640x901_051-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/640x901_051-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x703_051-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/640x901_051-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x703_051-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 945,
            "name": "052.png",
            "slug": "052-png",
            "src": "/static/_gallery/albums/interior-decoration/640x945_052-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/640x945_052-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/640x945_052-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x738_052-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/640x945_052-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x738_052-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 906,
            "name": "053.jpg",
            "slug": "053-jpg",
            "src": "/static/_gallery/albums/interior-decoration/640x906_053-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/640x906_053-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/640x906_053-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x707_053-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/640x906_053-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x707_053-jpg.jpg",
            "width": 640
         },
         {
            "faces": [],
            "height": 1936,
            "name": "054.jpg",
            "slug": "054-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1195_054-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x764_054-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1195_054-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x373_054-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x597_054-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x373_054-jpg.jpg",
            "width": 2592
         },
         {
            "faces": [],
            "height": 1936,
            "name": "055.jpg",
            "slug": "055-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1195_055-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x764_055-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1195_055-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x373_055-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x597_055-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x373_055-jpg.jpg",
            "width": 2592
         },
         {
            "faces": [],
            "height": 1936,
            "name": "056.jpg",
            "slug": "056-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1195_056-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x764_056-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1195_056-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x373_056-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x597_056-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x373_056-jpg.jpg",
            "width": 2592
         },
         {
            "faces": [],
            "height": 479,
            "name": "057.jpg",
            "slug": "057-jpg",
            "src": "/static/_gallery/albums/interior-decoration/720x479_057-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/720x479_057-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/720x479_057-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x332_057-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/720x479_057-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x332_057-jpg.jpg",
            "width": 720
         },
         {
            "faces": [],
            "height": 404,
            "name": "058.jpg",
            "slug": "058-jpg",
            "src": "/static/_gallery/albums/interior-decoration/720x404_058-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/720x404_058-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/720x404_058-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x280_058-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/720x404_058-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x280_058-jpg.jpg",
            "width": 720
         },
         {
            "faces": [],
            "height": 309,
            "name": "059.jpg",
            "slug": "059-jpg",
            "src": "/static/_gallery/albums/interior-decoration/476x309_059-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/476x309_059-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/476x309_059-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/476x309_059-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/476x309_059-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/476x309_059-jpg.jpg",
            "width": 476
         },
         {
            "faces": [],
            "height": 1136,
            "name": "060.jpg",
            "slug": "060-jpg",
            "src": "/static/_gallery/albums/interior-decoration/640x1136_060-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/640x1136_060-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/640x1136_060-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x887_060-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/640x1136_060-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x887_060-jpg.jpg",
            "width": 640
         },
         {
            "faces": [],
            "height": 1136,
            "name": "061.jpg",
            "slug": "061-jpg",
            "src": "/static/_gallery/albums/interior-decoration/640x1136_061-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/640x1136_061-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/640x1136_061-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x887_061-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/640x1136_061-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x887_061-jpg.jpg",
            "width": 640
         },
         {
            "faces": [],
            "height": 596,
            "name": "062.jpg",
            "slug": "062-jpg",
            "src": "/static/_gallery/albums/interior-decoration/667x596_062-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/667x596_062-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/667x596_062-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x446_062-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/667x596_062-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x446_062-jpg.jpg",
            "width": 667
         },
         {
            "faces": [],
            "height": 639,
            "name": "063.jpg",
            "slug": "063-jpg",
            "src": "/static/_gallery/albums/interior-decoration/960x639_063-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/960x639_063-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/960x639_063-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x332_063-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x532_063-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x332_063-jpg.jpg",
            "width": 960
         },
         {
            "faces": [],
            "height": 639,
            "name": "064.jpg",
            "slug": "064-jpg",
            "src": "/static/_gallery/albums/interior-decoration/960x639_064-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/960x639_064-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/960x639_064-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x332_064-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x532_064-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x332_064-jpg.jpg",
            "width": 960
         },
         {
            "faces": [],
            "height": 960,
            "name": "065.jpg",
            "slug": "065-jpg",
            "src": "/static/_gallery/albums/interior-decoration/640x960_065-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/640x960_065-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/640x960_065-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x750_065-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/640x960_065-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x750_065-jpg.jpg",
            "width": 640
         },
         {
            "faces": [],
            "height": 680,
            "name": "066.jpg",
            "slug": "066-jpg",
            "src": "/static/_gallery/albums/interior-decoration/960x680_066-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/960x680_066-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/960x680_066-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x354_066-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x566_066-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x354_066-jpg.jpg",
            "width": 960
         },
         {
            "faces": [],
            "height": 1280,
            "name": "067.jpg",
            "slug": "067-jpg",
            "src": "/static/_gallery/albums/interior-decoration/960x1280_067-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/960x1280_067-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/960x1280_067-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x666_067-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x1066_067-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x666_067-jpg.jpg",
            "width": 960
         },
         {
            "faces": [],
            "height": 1280,
            "name": "068.jpg",
            "slug": "068-jpg",
            "src": "/static/_gallery/albums/interior-decoration/747x1280_068-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/747x1280_068-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/747x1280_068-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x856_068-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/747x1280_068-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x856_068-jpg.jpg",
            "width": 747
         },
         {
            "faces": [],
            "height": 2448,
            "name": "069.jpg",
            "slug": "069-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_069-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_069-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_069-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_069-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_069-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_069-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 1136,
            "name": "070.png",
            "slug": "070-png",
            "src": "/static/_gallery/albums/interior-decoration/640x1136_070-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/640x1136_070-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/640x1136_070-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x887_070-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/640x1136_070-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x887_070-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 1136,
            "name": "071.png",
            "slug": "071-png",
            "src": "/static/_gallery/albums/interior-decoration/640x1136_071-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/640x1136_071-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/640x1136_071-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x887_071-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/640x1136_071-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x887_071-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 1136,
            "name": "072.png",
            "slug": "072-png",
            "src": "/static/_gallery/albums/interior-decoration/640x1136_072-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/640x1136_072-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/640x1136_072-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x887_072-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/640x1136_072-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x887_072-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 1136,
            "name": "073.png",
            "slug": "073-png",
            "src": "/static/_gallery/albums/interior-decoration/640x1136_073-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/640x1136_073-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/640x1136_073-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x887_073-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/640x1136_073-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x887_073-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 1136,
            "name": "074.png",
            "slug": "074-png",
            "src": "/static/_gallery/albums/interior-decoration/640x1136_074-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/640x1136_074-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/640x1136_074-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x887_074-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/640x1136_074-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x887_074-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 2448,
            "name": "075.jpg",
            "slug": "075-jpg",
            "src": "/static/_gallery/albums/interior-decoration/1600x1200_075-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/interior-decoration/1024x768_075-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/interior-decoration/1600x1200_075-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/interior-decoration/500x375_075-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/interior-decoration/800x600_075-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/interior-decoration/500x375_075-jpg.jpg",
            "width": 3264
         }
      ],
      "slug": "interior-decoration",
      "src": "/static/_gallery/albums/interior-decoration/500x450_000-jpeg.jpeg"
   },
   "kitchens": {
      "name": "kitchens",
      "photos": [
         {
            "faces": [],
            "height": 627,
            "name": "000.jpeg",
            "slug": "000-jpeg",
            "src": "/static/_gallery/albums/kitchens/500x627_000-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/kitchens/500x627_000-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/kitchens/500x627_000-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/kitchens/500x627_000-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/kitchens/500x627_000-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/kitchens/500x627_000-jpeg.jpeg",
            "width": 500
         },
         {
            "faces": [],
            "height": 1280,
            "name": "001.jpeg",
            "slug": "001-jpeg",
            "src": "/static/_gallery/albums/kitchens/960x1280_001-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/kitchens/960x1280_001-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/kitchens/960x1280_001-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/kitchens/500x666_001-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/kitchens/800x1066_001-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/kitchens/500x666_001-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 1280,
            "name": "002.jpeg",
            "slug": "002-jpeg",
            "src": "/static/_gallery/albums/kitchens/960x1280_002-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/kitchens/960x1280_002-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/kitchens/960x1280_002-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/kitchens/500x666_002-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/kitchens/800x1066_002-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/kitchens/500x666_002-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 960,
            "name": "003.jpeg",
            "slug": "003-jpeg",
            "src": "/static/_gallery/albums/kitchens/1280x960_003-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/kitchens/1024x768_003-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/kitchens/1280x960_003-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/kitchens/500x375_003-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/kitchens/800x600_003-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/kitchens/500x375_003-jpeg.jpeg",
            "width": 1280
         },
         {
            "faces": [],
            "height": 1280,
            "name": "004.jpeg",
            "slug": "004-jpeg",
            "src": "/static/_gallery/albums/kitchens/960x1280_004-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/kitchens/960x1280_004-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/kitchens/960x1280_004-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/kitchens/500x666_004-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/kitchens/800x1066_004-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/kitchens/500x666_004-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 606,
            "name": "004.png",
            "slug": "004-png",
            "src": "/static/_gallery/albums/kitchens/640x606_004-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/kitchens/640x606_004-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/kitchens/640x606_004-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/kitchens/500x473_004-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/kitchens/640x606_004-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/kitchens/500x473_004-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 720,
            "name": "005.jpeg",
            "slug": "005-jpeg",
            "src": "/static/_gallery/albums/kitchens/1280x720_005-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/kitchens/1024x576_005-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/kitchens/1280x720_005-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/kitchens/500x281_005-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/kitchens/800x450_005-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/kitchens/500x281_005-jpeg.jpeg",
            "width": 1280
         },
         {
            "faces": [],
            "height": 800,
            "name": "005.jpg",
            "slug": "005-jpg",
            "src": "/static/_gallery/albums/kitchens/600x800_005-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/kitchens/600x800_005-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/kitchens/600x800_005-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/kitchens/500x666_005-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/kitchens/600x800_005-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/kitchens/500x666_005-jpg.jpg",
            "width": 600
         },
         {
            "faces": [],
            "height": 720,
            "name": "006.jpeg",
            "slug": "006-jpeg",
            "src": "/static/_gallery/albums/kitchens/1280x720_006-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/kitchens/1024x576_006-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/kitchens/1280x720_006-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/kitchens/500x281_006-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/kitchens/800x450_006-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/kitchens/500x281_006-jpeg.jpeg",
            "width": 1280
         },
         {
            "faces": [],
            "height": 800,
            "name": "006.jpg",
            "slug": "006-jpg",
            "src": "/static/_gallery/albums/kitchens/600x800_006-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/kitchens/600x800_006-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/kitchens/600x800_006-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/kitchens/500x666_006-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/kitchens/600x800_006-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/kitchens/500x666_006-jpg.jpg",
            "width": 600
         },
         {
            "faces": [],
            "height": 720,
            "name": "007.jpeg",
            "slug": "007-jpeg",
            "src": "/static/_gallery/albums/kitchens/1280x720_007-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/kitchens/1024x576_007-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/kitchens/1280x720_007-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/kitchens/500x281_007-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/kitchens/800x450_007-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/kitchens/500x281_007-jpeg.jpeg",
            "width": 1280
         },
         {
            "faces": [],
            "height": 2448,
            "name": "007.jpg",
            "slug": "007-jpg",
            "src": "/static/_gallery/albums/kitchens/1600x1200_007-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/kitchens/1024x768_007-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/kitchens/1600x1200_007-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/kitchens/500x375_007-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/kitchens/800x600_007-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/kitchens/500x375_007-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 1280,
            "name": "008.jpeg",
            "slug": "008-jpeg",
            "src": "/static/_gallery/albums/kitchens/720x1280_008-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/kitchens/720x1280_008-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/kitchens/720x1280_008-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/kitchens/500x888_008-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/kitchens/720x1280_008-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/kitchens/500x888_008-jpeg.jpeg",
            "width": 720
         },
         {
            "faces": [],
            "height": 2448,
            "name": "008.jpg",
            "slug": "008-jpg",
            "src": "/static/_gallery/albums/kitchens/1600x1200_008-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/kitchens/1024x768_008-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/kitchens/1600x1200_008-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/kitchens/500x375_008-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/kitchens/800x600_008-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/kitchens/500x375_008-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 720,
            "name": "009.jpeg",
            "slug": "009-jpeg",
            "src": "/static/_gallery/albums/kitchens/1280x720_009-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/kitchens/1024x576_009-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/kitchens/1280x720_009-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/kitchens/500x281_009-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/kitchens/800x450_009-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/kitchens/500x281_009-jpeg.jpeg",
            "width": 1280
         },
         {
            "faces": [],
            "height": 2448,
            "name": "009.jpg",
            "slug": "009-jpg",
            "src": "/static/_gallery/albums/kitchens/1600x1200_009-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/kitchens/1024x768_009-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/kitchens/1600x1200_009-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/kitchens/500x375_009-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/kitchens/800x600_009-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/kitchens/500x375_009-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 720,
            "name": "010.jpeg",
            "slug": "010-jpeg",
            "src": "/static/_gallery/albums/kitchens/1280x720_010-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/kitchens/1024x576_010-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/kitchens/1280x720_010-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/kitchens/500x281_010-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/kitchens/800x450_010-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/kitchens/500x281_010-jpeg.jpeg",
            "width": 1280
         },
         {
            "faces": [],
            "height": 2448,
            "name": "010.jpg",
            "slug": "010-jpg",
            "src": "/static/_gallery/albums/kitchens/1600x1200_010-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/kitchens/1024x768_010-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/kitchens/1600x1200_010-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/kitchens/500x375_010-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/kitchens/800x600_010-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/kitchens/500x375_010-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 1280,
            "name": "011.jpeg",
            "slug": "011-jpeg",
            "src": "/static/_gallery/albums/kitchens/960x1280_011-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/kitchens/960x1280_011-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/kitchens/960x1280_011-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/kitchens/500x666_011-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/kitchens/800x1066_011-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/kitchens/500x666_011-jpeg.jpeg",
            "width": 960
         },
         {
            "faces": [],
            "height": 2448,
            "name": "011.jpg",
            "slug": "011-jpg",
            "src": "/static/_gallery/albums/kitchens/1600x1200_011-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/kitchens/1024x768_011-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/kitchens/1600x1200_011-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/kitchens/500x375_011-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/kitchens/800x600_011-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/kitchens/500x375_011-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "012.jpg",
            "slug": "012-jpg",
            "src": "/static/_gallery/albums/kitchens/1600x1200_012-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/kitchens/1024x768_012-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/kitchens/1600x1200_012-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/kitchens/500x375_012-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/kitchens/800x600_012-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/kitchens/500x375_012-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "013.jpg",
            "slug": "013-jpg",
            "src": "/static/_gallery/albums/kitchens/1600x1200_013-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/kitchens/1024x768_013-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/kitchens/1600x1200_013-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/kitchens/500x375_013-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/kitchens/800x600_013-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/kitchens/500x375_013-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 916,
            "name": "014.png",
            "slug": "014-png",
            "src": "/static/_gallery/albums/kitchens/640x916_014-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/kitchens/640x916_014-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/kitchens/640x916_014-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/kitchens/500x715_014-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/kitchens/640x916_014-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/kitchens/500x715_014-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 926,
            "name": "015.png",
            "slug": "015-png",
            "src": "/static/_gallery/albums/kitchens/640x926_015-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/kitchens/640x926_015-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/kitchens/640x926_015-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/kitchens/500x723_015-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/kitchens/640x926_015-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/kitchens/500x723_015-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 803,
            "name": "016.png",
            "slug": "016-png",
            "src": "/static/_gallery/albums/kitchens/640x803_016-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/kitchens/640x803_016-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/kitchens/640x803_016-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/kitchens/500x627_016-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/kitchens/640x803_016-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/kitchens/500x627_016-png.png",
            "width": 640
         }
      ],
      "slug": "kitchens",
      "src": "/static/_gallery/albums/kitchens/500x627_000-jpeg.jpeg"
   },
   "rocks": {
      "name": "rocks",
      "photos": [
         {
            "faces": [],
            "height": 713,
            "name": "001.jpeg",
            "slug": "001-jpeg",
            "src": "/static/_gallery/albums/rocks/500x713_001-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/500x713_001-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/500x713_001-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x713_001-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/500x713_001-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x713_001-jpeg.jpeg",
            "width": 500
         },
         {
            "faces": [],
            "height": 2448,
            "name": "002.jpg",
            "slug": "002-jpg",
            "src": "/static/_gallery/albums/rocks/1600x1200_002-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/1024x768_002-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/1600x1200_002-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x375_002-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/800x600_002-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x375_002-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 907,
            "name": "003.png",
            "slug": "003-png",
            "src": "/static/_gallery/albums/rocks/640x907_003-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/640x907_003-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/640x907_003-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x708_003-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/640x907_003-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x708_003-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 907,
            "name": "004.png",
            "slug": "004-png",
            "src": "/static/_gallery/albums/rocks/640x907_004-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/640x907_004-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/640x907_004-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x708_004-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/640x907_004-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x708_004-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 901,
            "name": "005.png",
            "slug": "005-png",
            "src": "/static/_gallery/albums/rocks/640x901_005-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/640x901_005-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/640x901_005-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x703_005-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/640x901_005-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x703_005-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 905,
            "name": "006.png",
            "slug": "006-png",
            "src": "/static/_gallery/albums/rocks/640x905_006-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/640x905_006-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/640x905_006-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x707_006-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/640x905_006-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x707_006-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 475,
            "name": "007.png",
            "slug": "007-png",
            "src": "/static/_gallery/albums/rocks/640x475_007-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/640x475_007-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/640x475_007-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x371_007-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/640x475_007-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x371_007-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 623,
            "name": "008.png",
            "slug": "008-png",
            "src": "/static/_gallery/albums/rocks/640x623_008-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/640x623_008-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/640x623_008-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x486_008-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/640x623_008-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x486_008-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 910,
            "name": "009.png",
            "slug": "009-png",
            "src": "/static/_gallery/albums/rocks/640x910_009-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/640x910_009-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/640x910_009-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x710_009-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/640x910_009-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x710_009-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 830,
            "name": "010.png",
            "slug": "010-png",
            "src": "/static/_gallery/albums/rocks/640x830_010-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/640x830_010-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/640x830_010-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x648_010-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/640x830_010-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x648_010-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 634,
            "name": "011.png",
            "slug": "011-png",
            "src": "/static/_gallery/albums/rocks/640x634_011-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/640x634_011-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/640x634_011-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x495_011-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/640x634_011-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x495_011-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 636,
            "name": "012.png",
            "slug": "012-png",
            "src": "/static/_gallery/albums/rocks/640x636_012-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/640x636_012-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/640x636_012-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x496_012-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/640x636_012-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x496_012-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 910,
            "name": "013.png",
            "slug": "013-png",
            "src": "/static/_gallery/albums/rocks/640x910_013-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/640x910_013-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/640x910_013-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x710_013-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/640x910_013-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x710_013-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 901,
            "name": "014.png",
            "slug": "014-png",
            "src": "/static/_gallery/albums/rocks/640x901_014-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/640x901_014-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/640x901_014-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x703_014-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/640x901_014-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x703_014-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 916,
            "name": "015.png",
            "slug": "015-png",
            "src": "/static/_gallery/albums/rocks/640x916_015-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/640x916_015-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/640x916_015-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x715_015-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/640x916_015-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x715_015-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 897,
            "name": "016.png",
            "slug": "016-png",
            "src": "/static/_gallery/albums/rocks/640x897_016-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/640x897_016-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/640x897_016-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x700_016-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/640x897_016-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x700_016-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 907,
            "name": "017.png",
            "slug": "017-png",
            "src": "/static/_gallery/albums/rocks/640x907_017-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/640x907_017-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/640x907_017-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x708_017-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/640x907_017-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x708_017-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 899,
            "name": "018.png",
            "slug": "018-png",
            "src": "/static/_gallery/albums/rocks/640x899_018-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/640x899_018-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/640x899_018-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x702_018-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/640x899_018-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x702_018-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 907,
            "name": "019.png",
            "slug": "019-png",
            "src": "/static/_gallery/albums/rocks/640x907_019-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/640x907_019-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/640x907_019-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x708_019-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/640x907_019-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x708_019-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 912,
            "name": "020.png",
            "slug": "020-png",
            "src": "/static/_gallery/albums/rocks/640x912_020-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/640x912_020-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/640x912_020-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x712_020-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/640x912_020-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x712_020-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 559,
            "name": "021.png",
            "slug": "021-png",
            "src": "/static/_gallery/albums/rocks/640x559_021-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/640x559_021-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/640x559_021-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x436_021-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/640x559_021-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x436_021-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 893,
            "name": "022.png",
            "slug": "022-png",
            "src": "/static/_gallery/albums/rocks/640x893_022-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/640x893_022-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/640x893_022-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x697_022-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/640x893_022-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x697_022-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 614,
            "name": "023.png",
            "slug": "023-png",
            "src": "/static/_gallery/albums/rocks/640x614_023-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/640x614_023-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/640x614_023-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x479_023-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/640x614_023-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x479_023-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 913,
            "name": "025.png",
            "slug": "025-png",
            "src": "/static/_gallery/albums/rocks/640x913_025-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/640x913_025-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/640x913_025-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x713_025-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/640x913_025-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x713_025-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 913,
            "name": "026.png",
            "slug": "026-png",
            "src": "/static/_gallery/albums/rocks/640x913_026-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/640x913_026-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/640x913_026-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x713_026-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/640x913_026-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x713_026-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 640,
            "name": "027.png",
            "slug": "027-png",
            "src": "/static/_gallery/albums/rocks/1136x640_027-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/1024x576_027-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/1136x640_027-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x281_027-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/800x450_027-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x281_027-png.png",
            "width": 1136
         },
         {
            "faces": [],
            "height": 2448,
            "name": "028.jpg",
            "slug": "028-jpg",
            "src": "/static/_gallery/albums/rocks/1600x1200_028-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/rocks/1024x768_028-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/rocks/1600x1200_028-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/rocks/500x375_028-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/rocks/800x600_028-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/rocks/500x375_028-jpg.jpg",
            "width": 3264
         }
      ],
      "slug": "rocks",
      "src": "/static/_gallery/albums/rocks/500x713_001-jpeg.jpeg"
   },
   "swimming-pools": {
      "name": "swimming pools",
      "photos": [
         {
            "faces": [],
            "height": 666,
            "name": "001.jpeg",
            "slug": "001-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/499x666_001-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/499x666_001-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/499x666_001-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/499x666_001-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/499x666_001-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/499x666_001-jpeg.jpeg",
            "width": 499
         },
         {
            "faces": [],
            "height": 2448,
            "name": "002.jpg",
            "slug": "002-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_002-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_002-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_002-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_002-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_002-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_002-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 529,
            "name": "003.png",
            "slug": "003-png",
            "src": "/static/_gallery/albums/swimming-pools/1366x529_003-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x396_003-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1366x529_003-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/499x193_003-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x309_003-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/499x193_003-png.png",
            "width": 1366
         },
         {
            "faces": [],
            "height": 2448,
            "name": "004.jpg",
            "slug": "004-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_004-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_004-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_004-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_004-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_004-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_004-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 1280,
            "name": "005.jpg",
            "slug": "005-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1023x1280_005-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1023x1280_005-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1023x1280_005-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x625_005-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x1000_005-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x625_005-jpg.jpg",
            "width": 1023
         },
         {
            "faces": [],
            "height": 2448,
            "name": "006.jpg",
            "slug": "006-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_006-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_006-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_006-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_006-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_006-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_006-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "007.jpg",
            "slug": "007-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_007-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_007-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_007-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_007-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_007-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_007-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "008.jpg",
            "slug": "008-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_008-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_008-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_008-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_008-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_008-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_008-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "009.jpg",
            "slug": "009-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_009-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_009-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_009-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_009-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_009-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_009-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "010.jpg",
            "slug": "010-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_010-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_010-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_010-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_010-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_010-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_010-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "011.jpg",
            "slug": "011-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_011-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_011-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_011-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_011-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_011-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_011-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "012.jpg",
            "slug": "012-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_012-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_012-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_012-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_012-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_012-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_012-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 960,
            "name": "013.jpg",
            "slug": "013-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1280x960_013-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_013-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1280x960_013-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_013-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_013-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_013-jpg.jpg",
            "width": 1280
         },
         {
            "faces": [],
            "height": 2448,
            "name": "014.jpg",
            "slug": "014-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_014-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_014-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_014-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_014-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_014-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_014-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "015.jpg",
            "slug": "015-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_015-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_015-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_015-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_015-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_015-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_015-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "016.jpg",
            "slug": "016-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_016-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_016-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_016-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_016-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_016-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_016-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "017.jpg",
            "slug": "017-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_017-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_017-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_017-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_017-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_017-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_017-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "018.jpg",
            "slug": "018-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_018-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_018-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_018-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_018-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_018-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_018-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "019.jpg",
            "slug": "019-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_019-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_019-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_019-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_019-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_019-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_019-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "020.jpg",
            "slug": "020-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_020-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_020-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_020-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_020-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_020-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_020-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 612,
            "name": "021.jpg",
            "slug": "021-jpg",
            "src": "/static/_gallery/albums/swimming-pools/816x612_021-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/816x612_021-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/816x612_021-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_021-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_021-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_021-jpg.jpg",
            "width": 816
         },
         {
            "faces": [],
            "height": 612,
            "name": "022.jpg",
            "slug": "022-jpg",
            "src": "/static/_gallery/albums/swimming-pools/816x612_022-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/816x612_022-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/816x612_022-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_022-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_022-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_022-jpg.jpg",
            "width": 816
         },
         {
            "faces": [],
            "height": 612,
            "name": "023.jpg",
            "slug": "023-jpg",
            "src": "/static/_gallery/albums/swimming-pools/816x612_023-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/816x612_023-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/816x612_023-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_023-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_023-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_023-jpg.jpg",
            "width": 816
         },
         {
            "faces": [],
            "height": 612,
            "name": "024.jpg",
            "slug": "024-jpg",
            "src": "/static/_gallery/albums/swimming-pools/816x612_024-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/816x612_024-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/816x612_024-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_024-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_024-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_024-jpg.jpg",
            "width": 816
         },
         {
            "faces": [],
            "height": 612,
            "name": "025.jpg",
            "slug": "025-jpg",
            "src": "/static/_gallery/albums/swimming-pools/816x612_025-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/816x612_025-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/816x612_025-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_025-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_025-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_025-jpg.jpg",
            "width": 816
         },
         {
            "faces": [],
            "height": 612,
            "name": "026.jpg",
            "slug": "026-jpg",
            "src": "/static/_gallery/albums/swimming-pools/816x612_026-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/816x612_026-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/816x612_026-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_026-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_026-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_026-jpg.jpg",
            "width": 816
         },
         {
            "faces": [],
            "height": 409,
            "name": "027.jpg",
            "slug": "027-jpg",
            "src": "/static/_gallery/albums/swimming-pools/600x409_027-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/600x409_027-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/600x409_027-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x340_027-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/600x409_027-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x340_027-jpg.jpg",
            "width": 600
         },
         {
            "faces": [],
            "height": 292,
            "name": "028.jpg",
            "slug": "028-jpg",
            "src": "/static/_gallery/albums/swimming-pools/600x292_028-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/600x292_028-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/600x292_028-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x243_028-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/600x292_028-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x243_028-jpg.jpg",
            "width": 600
         },
         {
            "faces": [],
            "height": 406,
            "name": "029.jpg",
            "slug": "029-jpg",
            "src": "/static/_gallery/albums/swimming-pools/600x406_029-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/600x406_029-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/600x406_029-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x338_029-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/600x406_029-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x338_029-jpg.jpg",
            "width": 600
         },
         {
            "faces": [],
            "height": 409,
            "name": "030.jpg",
            "slug": "030-jpg",
            "src": "/static/_gallery/albums/swimming-pools/600x409_030-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/600x409_030-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/600x409_030-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x340_030-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/600x409_030-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x340_030-jpg.jpg",
            "width": 600
         },
         {
            "faces": [],
            "height": 2448,
            "name": "031.jpg",
            "slug": "031-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_031-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_031-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_031-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_031-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_031-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_031-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "032.jpg",
            "slug": "032-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_032-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_032-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_032-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_032-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_032-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_032-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "033.jpg",
            "slug": "033-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1600_033-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x1024_033-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1600_033-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x500_033-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x800_033-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x500_033-jpg.jpg",
            "width": 2448
         },
         {
            "faces": [],
            "height": 2448,
            "name": "034.jpg",
            "slug": "034-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1600_034-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x1024_034-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1600_034-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x500_034-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x800_034-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x500_034-jpg.jpg",
            "width": 2448
         },
         {
            "faces": [],
            "height": 640,
            "name": "035.png",
            "slug": "035-png",
            "src": "/static/_gallery/albums/swimming-pools/1136x640_035-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x576_035-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1136x640_035-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x281_035-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x450_035-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x281_035-png.png",
            "width": 1136
         },
         {
            "faces": [],
            "height": 2448,
            "name": "036.jpg",
            "slug": "036-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_036-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_036-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_036-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_036-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_036-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_036-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 913,
            "name": "037.png",
            "slug": "037-png",
            "src": "/static/_gallery/albums/swimming-pools/640x913_037-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/640x913_037-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/640x913_037-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x713_037-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/640x913_037-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x713_037-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 929,
            "name": "038.png",
            "slug": "038-png",
            "src": "/static/_gallery/albums/swimming-pools/640x929_038-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/640x929_038-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/640x929_038-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x725_038-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/640x929_038-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x725_038-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 915,
            "name": "039.png",
            "slug": "039-png",
            "src": "/static/_gallery/albums/swimming-pools/640x915_039-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/640x915_039-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/640x915_039-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x714_039-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/640x915_039-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x714_039-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 2448,
            "name": "040.jpg",
            "slug": "040-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_040-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_040-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_040-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_040-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_040-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_040-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "041.jpg",
            "slug": "041-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_041-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_041-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_041-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_041-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_041-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_041-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "042.jpg",
            "slug": "042-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_042-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_042-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_042-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_042-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_042-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_042-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "043.jpg",
            "slug": "043-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_043-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_043-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_043-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_043-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_043-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_043-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "044.jpg",
            "slug": "044-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_044-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_044-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_044-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_044-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_044-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_044-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "045.jpg",
            "slug": "045-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_045-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_045-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_045-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_045-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_045-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_045-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "046.jpg",
            "slug": "046-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_046-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_046-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_046-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_046-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_046-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_046-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 565,
            "name": "048.png",
            "slug": "048-png",
            "src": "/static/_gallery/albums/swimming-pools/640x565_048-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/640x565_048-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/640x565_048-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x441_048-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/640x565_048-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x441_048-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 601,
            "name": "049.png",
            "slug": "049-png",
            "src": "/static/_gallery/albums/swimming-pools/640x601_049-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/640x601_049-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/640x601_049-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x469_049-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/640x601_049-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x469_049-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 916,
            "name": "050.png",
            "slug": "050-png",
            "src": "/static/_gallery/albums/swimming-pools/640x916_050-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/640x916_050-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/640x916_050-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x715_050-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/640x916_050-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x715_050-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 843,
            "name": "051.png",
            "slug": "051-png",
            "src": "/static/_gallery/albums/swimming-pools/640x843_051-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/640x843_051-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/640x843_051-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x658_051-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/640x843_051-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x658_051-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 561,
            "name": "052.png",
            "slug": "052-png",
            "src": "/static/_gallery/albums/swimming-pools/640x561_052-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/640x561_052-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/640x561_052-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x438_052-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/640x561_052-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x438_052-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 901,
            "name": "053.png",
            "slug": "053-png",
            "src": "/static/_gallery/albums/swimming-pools/640x901_053-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/640x901_053-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/640x901_053-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x703_053-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/640x901_053-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x703_053-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 551,
            "name": "054.png",
            "slug": "054-png",
            "src": "/static/_gallery/albums/swimming-pools/640x551_054-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/640x551_054-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/640x551_054-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x430_054-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/640x551_054-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x430_054-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 910,
            "name": "055.png",
            "slug": "055-png",
            "src": "/static/_gallery/albums/swimming-pools/640x910_055-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/640x910_055-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/640x910_055-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x710_055-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/640x910_055-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x710_055-png.png",
            "width": 640
         },
         {
            "faces": [],
            "height": 2448,
            "name": "056.jpg",
            "slug": "056-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_056-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_056-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_056-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_056-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_056-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_056-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "057.jpg",
            "slug": "057-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_057-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_057-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_057-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_057-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_057-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_057-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "058.jpg",
            "slug": "058-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_058-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_058-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_058-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_058-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_058-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_058-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "059.jpg",
            "slug": "059-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_059-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_059-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_059-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_059-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_059-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_059-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "060.jpg",
            "slug": "060-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_060-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_060-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_060-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_060-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_060-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_060-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "061.jpg",
            "slug": "061-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_061-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_061-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_061-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_061-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_061-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_061-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "062.jpg",
            "slug": "062-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_062-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_062-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_062-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_062-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_062-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_062-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "063.jpg",
            "slug": "063-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_063-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_063-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_063-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_063-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_063-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_063-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "064.jpg",
            "slug": "064-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_064-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_064-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_064-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_064-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_064-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_064-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "065.jpg",
            "slug": "065-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_065-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_065-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_065-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_065-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_065-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_065-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "066.jpg",
            "slug": "066-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_066-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_066-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_066-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_066-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_066-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_066-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "067.jpg",
            "slug": "067-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_067-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_067-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_067-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_067-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_067-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_067-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "068.jpg",
            "slug": "068-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_068-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_068-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_068-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_068-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_068-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_068-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "069.jpg",
            "slug": "069-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_069-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_069-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_069-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_069-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_069-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_069-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 576,
            "name": "070.jpg",
            "slug": "070-jpg",
            "src": "/static/_gallery/albums/swimming-pools/960x576_070-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/960x576_070-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/960x576_070-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x300_070-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x480_070-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x300_070-jpg.jpg",
            "width": 960
         },
         {
            "faces": [],
            "height": 2448,
            "name": "071.jpg",
            "slug": "071-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_071-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_071-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_071-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_071-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_071-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_071-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "072.jpg",
            "slug": "072-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_072-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_072-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_072-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_072-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_072-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_072-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 1187,
            "name": "073.jpg",
            "slug": "073-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1187_073-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x759_073-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1187_073-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x370_073-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x593_073-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x370_073-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 975,
            "name": "074.jpg",
            "slug": "074-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1500x975_074-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x665_074-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1500x975_074-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x325_074-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x520_074-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x325_074-jpg.jpg",
            "width": 1500
         },
         {
            "faces": [],
            "height": 1187,
            "name": "075.jpg",
            "slug": "075-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1187_075-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x759_075-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1187_075-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x370_075-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x593_075-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x370_075-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1068,
            "name": "076.jpg",
            "slug": "076-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1424x1068_076-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_076-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1424x1068_076-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_076-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_076-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_076-jpg.jpg",
            "width": 1424
         },
         {
            "faces": [],
            "height": 1068,
            "name": "077.jpg",
            "slug": "077-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1424x1068_077-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_077-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1424x1068_077-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_077-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_077-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_077-jpg.jpg",
            "width": 1424
         },
         {
            "faces": [],
            "height": 1187,
            "name": "078.jpg",
            "slug": "078-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1187_078-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x759_078-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1187_078-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x370_078-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x593_078-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x370_078-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1187,
            "name": "079.jpg",
            "slug": "079-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1187_079-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x759_079-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1187_079-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x370_079-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x593_079-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x370_079-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1187,
            "name": "080.jpg",
            "slug": "080-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1187_080-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x759_080-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1187_080-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x370_080-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x593_080-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x370_080-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1200,
            "name": "081.jpg",
            "slug": "081-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_081-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_081-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_081-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_081-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_081-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_081-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1600,
            "name": "082.jpg",
            "slug": "082-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1200x1600_082-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x1365_082-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1200x1600_082-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x666_082-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x1066_082-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x666_082-jpg.jpg",
            "width": 1200
         },
         {
            "faces": [],
            "height": 1201,
            "name": "083.jpg",
            "slug": "083-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1201_083-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_083-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1201_083-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_083-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_083-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_083-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1187,
            "name": "084.jpg",
            "slug": "084-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1187_084-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x759_084-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1187_084-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x370_084-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x593_084-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x370_084-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1201,
            "name": "085.jpg",
            "slug": "085-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1201_085-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_085-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1201_085-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_085-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_085-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_085-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1068,
            "name": "086.jpg",
            "slug": "086-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1424x1068_086-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_086-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1424x1068_086-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_086-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_086-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_086-jpg.jpg",
            "width": 1424
         },
         {
            "faces": [],
            "height": 1200,
            "name": "087.jpg",
            "slug": "087-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_087-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_087-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_087-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_087-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_087-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_087-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1068,
            "name": "088.jpg",
            "slug": "088-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1424x1068_088-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_088-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1424x1068_088-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_088-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_088-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_088-jpg.jpg",
            "width": 1424
         },
         {
            "faces": [],
            "height": 1200,
            "name": "089.jpg",
            "slug": "089-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_089-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_089-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_089-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_089-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_089-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_089-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1187,
            "name": "090.jpg",
            "slug": "090-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1187_090-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x759_090-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1187_090-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x370_090-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x593_090-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x370_090-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1200,
            "name": "091.jpg",
            "slug": "091-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_091-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_091-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_091-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_091-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_091-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_091-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1187,
            "name": "092.jpg",
            "slug": "092-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1187_092-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x759_092-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1187_092-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x370_092-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x593_092-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x370_092-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1200,
            "name": "093.jpg",
            "slug": "093-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_093-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_093-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_093-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_093-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_093-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_093-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1200,
            "name": "094.jpg",
            "slug": "094-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_094-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_094-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_094-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_094-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_094-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_094-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1200,
            "name": "095.jpg",
            "slug": "095-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_095-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_095-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_095-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_095-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_095-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_095-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1200,
            "name": "096.jpg",
            "slug": "096-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_096-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_096-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_096-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_096-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_096-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_096-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1200,
            "name": "097.jpg",
            "slug": "097-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_097-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_097-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_097-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_097-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_097-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_097-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1200,
            "name": "098.jpg",
            "slug": "098-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_098-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_098-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_098-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_098-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_098-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_098-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1200,
            "name": "099.jpg",
            "slug": "099-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_099-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_099-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_099-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_099-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_099-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_099-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1201,
            "name": "100.jpg",
            "slug": "100-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1201_100-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_100-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1201_100-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_100-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_100-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_100-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1200,
            "name": "101.jpg",
            "slug": "101-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_101-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_101-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_101-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_101-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_101-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_101-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1201,
            "name": "102.jpg",
            "slug": "102-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1201_102-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_102-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1201_102-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_102-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_102-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_102-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1201,
            "name": "103.jpg",
            "slug": "103-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1201_103-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_103-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1201_103-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_103-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_103-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_103-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1187,
            "name": "104.jpg",
            "slug": "104-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1187_104-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x759_104-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1187_104-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x370_104-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x593_104-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x370_104-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1200,
            "name": "105.jpg",
            "slug": "105-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_105-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_105-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_105-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_105-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_105-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_105-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1245,
            "name": "106.jpg",
            "slug": "106-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1245_106-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x796_106-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1245_106-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x389_106-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x622_106-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x389_106-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1201,
            "name": "107.jpg",
            "slug": "107-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1201_107-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_107-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1201_107-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_107-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_107-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_107-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1200,
            "name": "108.jpg",
            "slug": "108-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_108-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_108-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_108-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_108-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_108-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_108-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 1200,
            "name": "109.jpg",
            "slug": "109-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_109-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_109-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_109-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_109-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_109-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_109-jpg.jpg",
            "width": 1600
         },
         {
            "faces": [],
            "height": 2448,
            "name": "110.jpg",
            "slug": "110-jpg",
            "src": "/static/_gallery/albums/swimming-pools/1600x1200_110-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_110-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1600x1200_110-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_110-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_110-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_110-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 666,
            "name": "112.jpeg",
            "slug": "112-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/1024x666_112-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x666_112-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1024x666_112-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x325_112-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x520_112-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x325_112-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 768,
            "name": "113.jpeg",
            "slug": "113-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/1024x768_113-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_113-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_113-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_113-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_113-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_113-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 960,
            "name": "114.jpeg",
            "slug": "114-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/720x960_114-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/720x960_114-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/720x960_114-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x666_114-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/720x960_114-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x666_114-jpeg.jpeg",
            "width": 720
         },
         {
            "faces": [],
            "height": 1024,
            "name": "115.jpeg",
            "slug": "115-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/768x1024_115-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/768x1024_115-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/768x1024_115-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x666_115-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/768x1024_115-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x666_115-jpeg.jpeg",
            "width": 768
         },
         {
            "faces": [],
            "height": 768,
            "name": "116.jpeg",
            "slug": "116-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/1024x768_116-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_116-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_116-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_116-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_116-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_116-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 768,
            "name": "117.jpeg",
            "slug": "117-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/1024x768_117-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_117-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_117-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_117-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_117-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_117-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 768,
            "name": "118.jpeg",
            "slug": "118-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/1024x768_118-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_118-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_118-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_118-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_118-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_118-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 768,
            "name": "119.jpeg",
            "slug": "119-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/1024x768_119-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_119-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_119-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_119-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_119-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_119-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 768,
            "name": "120.jpeg",
            "slug": "120-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/1024x768_120-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_120-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_120-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_120-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_120-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_120-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 768,
            "name": "121.jpeg",
            "slug": "121-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/1024x768_121-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_121-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_121-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_121-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_121-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_121-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 768,
            "name": "122.jpeg",
            "slug": "122-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/1024x768_122-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_122-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_122-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_122-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_122-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_122-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 768,
            "name": "123.jpeg",
            "slug": "123-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/1024x768_123-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_123-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_123-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_123-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_123-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_123-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 1024,
            "name": "124.jpeg",
            "slug": "124-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/768x1024_124-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/768x1024_124-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/768x1024_124-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x666_124-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/768x1024_124-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x666_124-jpeg.jpeg",
            "width": 768
         },
         {
            "faces": [],
            "height": 768,
            "name": "125.jpeg",
            "slug": "125-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/1024x768_125-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_125-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_125-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_125-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_125-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_125-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 768,
            "name": "126.jpeg",
            "slug": "126-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/1024x768_126-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_126-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_126-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_126-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_126-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_126-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 768,
            "name": "127.jpeg",
            "slug": "127-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/1024x768_127-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_127-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_127-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_127-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_127-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_127-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 471,
            "name": "128.jpeg",
            "slug": "128-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/1024x471_128-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x471_128-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1024x471_128-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x229_128-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x367_128-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x229_128-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 471,
            "name": "129.jpeg",
            "slug": "129-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/1024x471_129-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x471_129-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1024x471_129-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x229_129-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x367_129-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x229_129-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 768,
            "name": "130.jpeg",
            "slug": "130-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/1024x768_130-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_130-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_130-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_130-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_130-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_130-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 1024,
            "name": "131.jpeg",
            "slug": "131-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/576x1024_131-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/576x1024_131-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/576x1024_131-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x888_131-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/576x1024_131-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x888_131-jpeg.jpeg",
            "width": 576
         },
         {
            "faces": [],
            "height": 768,
            "name": "132.jpeg",
            "slug": "132-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/1024x768_132-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_132-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_132-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_132-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_132-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_132-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 1024,
            "name": "133.jpeg",
            "slug": "133-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/768x1024_133-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/768x1024_133-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/768x1024_133-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x666_133-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/768x1024_133-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x666_133-jpeg.jpeg",
            "width": 768
         },
         {
            "faces": [],
            "height": 471,
            "name": "134.jpeg",
            "slug": "134-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/1024x471_134-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x471_134-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1024x471_134-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x229_134-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x367_134-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x229_134-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 768,
            "name": "135.jpeg",
            "slug": "135-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/1024x768_135-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_135-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_135-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_135-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_135-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_135-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 1024,
            "name": "136.jpeg",
            "slug": "136-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/768x1024_136-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/768x1024_136-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/768x1024_136-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x666_136-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/768x1024_136-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x666_136-jpeg.jpeg",
            "width": 768
         },
         {
            "faces": [],
            "height": 768,
            "name": "137.jpeg",
            "slug": "137-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/1024x768_137-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_137-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_137-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_137-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_137-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_137-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 768,
            "name": "138.jpeg",
            "slug": "138-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/1024x768_138-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_138-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_138-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_138-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_138-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_138-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 1024,
            "name": "139.jpeg",
            "slug": "139-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/768x1024_139-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/768x1024_139-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/768x1024_139-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x666_139-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/768x1024_139-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x666_139-jpeg.jpeg",
            "width": 768
         },
         {
            "faces": [],
            "height": 768,
            "name": "140.jpeg",
            "slug": "140-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/1024x768_140-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_140-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_140-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_140-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_140-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_140-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 768,
            "name": "141.jpeg",
            "slug": "141-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/1024x768_141-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_141-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_141-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_141-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_141-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_141-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 768,
            "name": "142.jpeg",
            "slug": "142-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/1024x768_142-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_142-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_142-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_142-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_142-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_142-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 600,
            "name": "143.jpeg",
            "slug": "143-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/800x600_143-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_143-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_143-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_143-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_143-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_143-jpeg.jpeg",
            "width": 800
         },
         {
            "faces": [],
            "height": 533,
            "name": "144.jpeg",
            "slug": "144-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/800x533_144-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/800x533_144-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/800x533_144-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x333_144-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x533_144-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x333_144-jpeg.jpeg",
            "width": 800
         },
         {
            "faces": [],
            "height": 522,
            "name": "145.jpeg",
            "slug": "145-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/800x522_145-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/800x522_145-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/800x522_145-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x326_145-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x522_145-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x326_145-jpeg.jpeg",
            "width": 800
         },
         {
            "faces": [],
            "height": 398,
            "name": "146.jpeg",
            "slug": "146-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/530x398_146-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/530x398_146-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/530x398_146-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_146-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/530x398_146-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_146-jpeg.jpeg",
            "width": 530
         },
         {
            "faces": [],
            "height": 1024,
            "name": "147.jpeg",
            "slug": "147-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/768x1024_147-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/768x1024_147-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/768x1024_147-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x666_147-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/768x1024_147-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x666_147-jpeg.jpeg",
            "width": 768
         },
         {
            "faces": [],
            "height": 768,
            "name": "152.jpeg",
            "slug": "152-jpeg",
            "src": "/static/_gallery/albums/swimming-pools/1024x768_152-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_152-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/1024x768_152-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_152-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_152-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_152-jpeg.jpeg",
            "width": 1024
         },
         {
            "faces": [],
            "height": 651,
            "name": "153.png",
            "slug": "153-png",
            "src": "/static/_gallery/albums/swimming-pools/868x651_153-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/swimming-pools/868x651_153-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/swimming-pools/868x651_153-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/swimming-pools/500x375_153-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/swimming-pools/800x600_153-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/swimming-pools/500x375_153-png.png",
            "width": 868
         }
      ],
      "slug": "swimming-pools",
      "src": "/static/_gallery/albums/swimming-pools/499x666_001-jpeg.jpeg"
   }
};